import React from 'react';
import CardWithBackgroundImage from 'components/CardWithBackgroundImage';
import RotatingImages from 'components/RotatingImages';
import { ASSET_PREFIX } from 'utils/constants';

const PrizeCategoriesImageGallery = ({ }) => {
  const IMAGE_DIR = '/common';
  const images = [
    {
      renderItem: () => (
        <CardWithBackgroundImage
          key="trips-and-stays"
          id="trips-and-stays"
          imageDirectory={IMAGE_DIR}
          imageFname="win-trips-and-stays-bg"
          imageOpacity={0.9}
        >
          <img
            id="trips-and-stays-heading"
            className="categoryHeading"
            src={`${ASSET_PREFIX}/images/common/win-trips-and-stays.svg`}
            alt="Win trips and stays"
          />
        </CardWithBackgroundImage>
      ),
    },
    {
      renderItem: () => (
        <CardWithBackgroundImage
          key="tech-prizes"
          id="tech-prizes"
          imageDirectory={IMAGE_DIR}
          imageFname="win-tech-prizes-bg"
          imageOpacity={0.9}
        >
          <img
            id="tech-prizes-heading"
            className="categoryHeading"
            src={`${ASSET_PREFIX}/images/common/win-tech-prizes.svg`}
            alt="Win tech prizes"
          />
        </CardWithBackgroundImage>
      ),
    },
    {
      renderItem: () => (
        <CardWithBackgroundImage
          key="lifestyle-prizes"
          id="lifestyle-prizes"

          imageDirectory={IMAGE_DIR}
          imageFname="win-lifestyle-prizes-bg"
          imageOpacity={0.9}
        >
          <img
            id="lifestyle-prizes-heading"
            className="categoryHeading"
            src={`${ASSET_PREFIX}/images/common/win-lifestyle-prizes.svg`}
            alt="Win lifestyle prizes"
          />
        </CardWithBackgroundImage>
      ),
    },
    {
      renderItem: () => (
        <CardWithBackgroundImage
          key="experiences"
          id="experiences"
          imageDirectory={IMAGE_DIR}
          imageFname="win-experiences-bg"
          imageOpacity={0.9}
        >
          <img
            id="experiences-heading"
            className="categoryHeading"
            src={`${ASSET_PREFIX}/images/common/win-experiences.svg`}
            alt="Win experiences"
          />
        </CardWithBackgroundImage>
      ),
    },
  ];

  return (
    <div className="prizeCategoriesImageGallery">
      <RotatingImages images={images} additionalClass="gallery" />

      <style jsx>
        {`
          :global(.prizeCategoriesImageGallery .gallery) {
            width: 100%;
            overflow: hidden;
          }
          
          :global(.prizeCategoriesImageGallery .cardWithBackgroundImage) {
            padding: 5em 0;
            background: black;
          }
          
          :global(.prizeCategoriesImageGallery #trips-and-stays-heading) {
            width: 50%;
          }
          
          :global(.prizeCategoriesImageGallery #tech-prizes-heading) {
            width: 45%;
          }
          
          :global(.prizeCategoriesImageGallery #lifestyle-prizes-heading) {
            width: 60%;
          }
          
          :global(.prizeCategoriesImageGallery #experiences-heading) {
            width: 70%;
          }
        `}
      </style>
    </div>
  );
};

export default PrizeCategoriesImageGallery;
