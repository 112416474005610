import React from 'react';
import Link from 'next/link';
import CardList from 'components/CardList';
import svStyles from 'components/landing/StrongerVariant/StrongerVariant.module.css';
import RoundedButton from '../../../RoundedButton';
import s from './WinnersSection.module.css';
import { shuffleArray } from 'utils/common';
import Subheading from 'components/Subheading';
import { winnerPhotoNames } from 'constants/winnerPhotoNames';
import Separator from 'components/Separator';
import QuoteCard from 'components/landing/StrongerVariant/WinnersSection/QuoteCard/QuoteCard';
import ImageWithFallback from 'components/ImageWithFallback';

const WinnersSection = ({ withCta, ctaText, ctaHandler }) => {
  shuffleArray(winnerPhotoNames); // Randomise order
  return (
    <div
      className={`winnersSection sectionContainer ${svStyles.sectionContainer}`}
      id={s.winnersSectionContainer}
      style={{ padding: '3em 0 4em' }}
    >
      <div className="paddedMaxWidthContainer">
        <Subheading>
          Are You Ready To Win <strong>Prizes?</strong>
        </Subheading>

        <p style={{ fontWeight: 600, maxWidth: 800 }}>
          We’ve seen some epic wins so far this year. Will YOU be next? With
          winners every week, you could be the next person to {''}
          <Link href="/prizes/trips-and-stays">
            <a style={{ textDecoration: 'underline' }}>win epic holidays</a>
          </Link>
          , unforgettable {''}
          <Link href="/prizes/experiences">
            <a style={{ textDecoration: 'underline' }}>experiences</a>
          </Link>
          , your favourite {''}
          <Link href="/prizes/tech">
            <a style={{ textDecoration: 'underline' }}>tech competitions</a>
          </Link>
          , and {''}
          <Link href="/prizes/lifestyle-and-beauty">
            <a style={{ textDecoration: 'underline' }}>lifestyle prizes</a>
          </Link>
          !
        </p>
      </div>

      <CardList
        id="wallOfFame"
        className={s.wallOfFame}
        noDots
      >
        {winnerPhotoNames.map((name) => (
          <div
            key={name}
            className="gridPhoto"
          >
            <ImageWithFallback
              src={`/images/winners/wall-of-fame/${name}.webp`}
              fallbackSrc={`/images/winners/wall-of-fame/${name}.jpg`}
              className={s.gridPhoto}
              layout="fill"
              alt="DAYMADE winner"
            />
          </div>
        ))}
      </CardList>

      <div className="paddedMaxWidthContainer">
        <p style={{ fontWeight: 600, maxWidth: 800 }}>
          Join the growing DAYMADE community and enter our paid or {''}
          <Link href="/token-town/free-daily-draw">
            <a style={{ textDecoration: 'underline' }}>free draws</a>
          </Link>
          {''} now for your chance to feature on our {''}
          <Link href="/winners">
            <a style={{ textDecoration: 'underline' }}>Wall of Winners</a>
          </Link>
          .
        </p>
      </div>

      <div
        className="flexCentered fullWidth"
        style={{ maxWidth: '1000px' }}
      >
        <Separator
          style={{ margin: '2em auto', width: '95%', maxWidth: 980 }}
        />

        <Subheading style={{ margin: '0.4em 1em 1em' }}>
          Read Our Raving <strong>Reviews</strong>
        </Subheading>

        <p>
          We're not rated 4.7* on {''}
          <Link href="https://uk.trustpilot.com/review/daymade.co.uk">
            <a
              style={{ textDecoration: 'underline' }}
              target="_blank"
            >
              Trustpilot
            </a>
          </Link>
          {''} for nothing!
        </p>

        <CardList
          id="reviewList"
          style={{ margin: '0 auto 1em', width: '100%', padding: '1em' }}
        >
          {[
            {
              quoteText:
                "Out of all the draws so far, I have only had 1 that I didn't win on",
              name: 'Naomi',
            },
            {
              quoteText:
                'Thank you Daymade for being honest, fun and adding a bit of sparkle to the week!!',
              name: 'Karin',
            },
            {
              quoteText:
                'I managed to win vouchers for £150 twice & even one of the £2500 prizes 🎉',
              name: 'Lussy',
            },
            {
              quoteText: 'I’ve never won anything before but I’ve won 3 times!',
              name: 'Misha',
            },
          ].map((quote, i) => (
            <QuoteCard
              key={i}
              quoteText={quote.quoteText}
              name={quote.name}
            />
          ))}
        </CardList>
      </div>

      {withCta && ctaText && (
        <RoundedButton
          onClick={ctaHandler}
          style={{ minWidth: '300px', marginTop: '2em', textShadow: 'none' }}
        >
          {ctaText}
        </RoundedButton>
      )}

      <style jsx>
        {`
          :global(.winnersSection .cardList) {
            flex-direction: column;
            height: auto;
            max-height: 430px;
            flex-wrap: wrap;
            margin-top: 1em;
          }

          .gridPhoto {
            position: relative;
            margin: 5px;
            width: 150px;
            height: 150px;
          }

          @media (min-width: 1000px) {
            .gridPhoto {
              width: 200px;
              height: 200px;
            }
          }

          @media (min-width: 1600px) {
            :global(#wallOfFame) {
              max-height: 530px;
            }

            .gridPhoto {
              width: 250px;
              height: 250px;
            }
          }

          :global(.footer) {
            margin-top: 0 !important;
          }
        `}
      </style>
    </div>
  );
};

export default WinnersSection;
