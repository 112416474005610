import Link from 'next/link';
import Subheading, { SubheadingColourScheme } from 'components/Subheading';
import React from 'react';
import CardWithBackgroundImage from 'components/CardWithBackgroundImage';

const BlogArticleCard = ({ title, subheading, datePostedString, imageDirectory, href, backgroundPosition = 'center', target = '_self', style }) => {
	return (
		<Link href={href} passHref>
			<a target={target} className="blogArticleCard fullWidth flexCentered" style={style}>
				<CardWithBackgroundImage
					id="blogArticleCard"
					imageDirectory={imageDirectory}
					imageFname="hero"
					imageOpacity="0.52"
				>
					<Subheading Tag='h4' className="blogArticleCardTitle" colourScheme={SubheadingColourScheme.WHITE_TEXT_BLACK_SHADOW}>
						{title}
					</Subheading>

					<div className='separator' style={{ alignSelf: 'center', margin: '0.6em', width: '85%' }} />

					<p>
						{subheading}
					</p>

					<p style={{ fontWeight: '600', justifySelf: 'flex-end' }}>
						{datePostedString}
					</p>

				</CardWithBackgroundImage>

				<style jsx>{`
					:global(.blogArticleCard .cardWithBackgroundImage) {
						height: 100%;
						padding: 1.25em;
						border-radius: 4px;
					}
				
					.blogArticleCard {
						position: relative;
						color: white;
						font-size: 0.8em;
						text-align: center;
						height: 180px;
						background-color: black;
						border-radius: 4px;
						box-shadow: 0 0 5px gray, inset 0 0 0 1000px rgba(0,0,0,.5);
						cursor: pointer;
						white-space: pre-wrap;
						word-wrap: break-word;
					}
					
					:global(.subheading.blogArticleCardTitle) {
					  font-size: 1.6em;
					}
					
					@media(min-width: 440px) {
						.blogArticleCard {
							height: 280px;
							font-size: 1.2em;
						}
					}
					
					.blogArticleCard p {
						font-weight: 600;
						font-size: 1.2em;
						font-style: italic;
					}
				`}</style>
			</a>
		</Link>
	);
};

export default BlogArticleCard;
