import { NextRouter } from 'next/router';
import {
  EecProduct,
  trackEecProductClick,
  trackEvent,
} from 'utils/analytics/analytics';
import { getCta, scrollToSection } from 'utils/common';
import $ from 'jquery';
import Router from 'next/router';

export const ctaHandler = (
  subscriberStatus: 'FREEMIUM' | 'LITE' | 'PREMIUM' | null
) => {
  if ($('#entryMethodSelectionSection').length > 0 && !subscriberStatus) {
    return () => {
      trackEvent('Home Enter CTA');

      scrollToSection('#entryMethodSelectionSection');
    };
  }
  if (subscriberStatus) {
    switch (subscriberStatus) {
      case 'FREEMIUM':
      case 'LITE':
        return () => Router.push('/account/subscriptions/upgrade');

      case 'PREMIUM':
      default:
        return null;
    }
  }

  return async () => {
    Router.push(getCta()!.url);

    // Track product click with GA EEC.
    await trackEecProductClick('home-page', EecProduct.LOTTERY);
  };
};

const goToUpgradeFlow = () => {};
