import React from 'react';
import ImageGallery from 'react-image-gallery';
import { extendClassName } from 'utils/common';

const RotatingImages = ({ images, customSlideInterval, hideBullets, additionalClass }) => {
  return (
    <ImageGallery
      additionalClass={extendClassName('rotatingImages', additionalClass)}
      items={images}
      slideInterval={customSlideInterval || 5000}
      showBullets={!hideBullets && images?.length > 1}
      autoPlay
      disableKeyDown
      showThumbnails={false}
      showFullscreenButton={false}
      useBrowserFullscreen={false}
      showPlayButton={false}
      showNav={false}
    />
  );
};

export default RotatingImages;
