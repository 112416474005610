const IMAGE_DIR = '/blog/articles';

export const BLOG_ARTICLES = [
  {
    title: <>Road-Trip-Wonders: Must-See Routes and Itineraries</>,
    subheading: 'Are you ready for an epic adventure on the open road?',
    datePostedString: '15/11/2023',
    imageDirectory: `${IMAGE_DIR}/road-trip-wonders`,
    href: '/blog/road-trip-wonders',
  },
  {
    title: <>Family-Friendly Vacations & Days Out</>,
    subheading: 'Our guide to create unforgettable memories with your kids',
    datePostedString: '08/11/2023',
    imageDirectory: `${IMAGE_DIR}/family-friendly-vacations`,
    href: '/blog/family-friendly-vacations',
  },
  {
    title: <>A Food Lover's Paradise: Culinary Delights Around the World</>,
    subheading:
      'Get ready to embark on a gastronomic journey as we explore some of the most delectable destinations around the world.',
    datePostedString: '01/11/2023',
    imageDirectory: `${IMAGE_DIR}/culinary-delights-around-the-world`,
    href: '/blog/culinary-delights-around-the-world',
  },
  {
    title: <>Winter Wonderland: Best Ski Resorts and Snowy Retreats</>,
    subheading:
      "If you're ready to hit the slopes and experience the thrill of a winter wonderland, we've got you covered with our guide.",
    datePostedString: '18/10/2023',
    imageDirectory: `${IMAGE_DIR}/best-ski-resorts-and-snowy-retreats`,
    href: '/blog/winter-wonderland-best-ski-trips',
  },
  {
    title: <>DAYMADE Has Planted 1 MILLION Trees! </>,
    subheading: 'Making a true difference by planting one tree at a time',
    datePostedString: '04/10/23',
    imageDirectory: `${IMAGE_DIR}/daymade-has-planted-1-million-trees`,
    href: '/blog/daymade-has-planted-1-million-trees',
  },
  {
    title: <>How DAYMADE Celebrates International Women's Day 2023</>,
    subheading: 'A spotlight on our female founded prizes',
    datePostedString: '08/03/23',
    imageDirectory: `${IMAGE_DIR}/how-daymade-celebrates-international-womens-day-2023`,
    href: '/blog/how-daymade-celebrates-international-womens-day-2023',
  },
  {
    title: <>Travel on a budget</>,
    subheading: 'DAYMADE to the rescue!',
    datePostedString: '27/02/23',
    imageDirectory: `${IMAGE_DIR}/travel-on-a-budget`,
    href: '/blog/travel-on-a-budget',
  },
  {
    title: <>DAYMADE's Top 8 Stocking Fillers of 2022</>,
    subheading:
      'Have the ultimate Christmas morning with these unique festive treats.',
    datePostedString: '12/12/22',
    imageDirectory: `${IMAGE_DIR}/Top-8-Christmas-Stocking-Fillers-2022`,
    href: '/blog/Top-8-Christmas-Stocking-Fillers-2022',
  },
  {
    title: <>National Tree Week: An Update from Daymade</>,
    subheading: 'Let’s celebrate the heroes of nature',
    datePostedString: '01/12/22',
    imageDirectory: `${IMAGE_DIR}/national-tree-week-update`,
    href: '/blog/national-tree-week-update',
  },
  {
    title: <>The Psychology Behind Different Experiences</>,
    subheading: 'Discover why experiences make us feel good',
    datePostedString: '11/11/22',
    imageDirectory: `${IMAGE_DIR}/psychology-behind-different-experiences`,
    href: '/blog/psychology-behind-different-experiences',
  },
  {
    title: <>6 of the best Halloween Events in the UK</>,
    subheading: 'Get your spooky fever on this Halloween',
    datePostedString: '21/10/22',
    imageDirectory: `${IMAGE_DIR}/6-best-halloween-events-in-the-uk`,
    href: '/blog/6-best-halloween-events-in-the-uk',
  },
  {
    title: <>How to Enjoy your Holidays to the Max</>,
    subheading: 'Let’s make your holiday the best one yet',
    datePostedString: '29/09/22',
    imageDirectory: `${IMAGE_DIR}/how-to-enjoy-your-holidays-to-the-max`,
    href: '/blog/how-to-enjoy-your-holidays-to-the-max',
  },
  {
    title: <>Best 4 Sustainable Travel Companies</>,
    subheading: 'Epic travel adventures that make you and the planet feel good',
    datePostedString: '08/09/22',
    imageDirectory: `${IMAGE_DIR}/best-4-sustainable-travel-companies-2022`,
    href: '/blog/best-4-sustainable-travel-companies-2022',
  },
  {
    title: <>Top 5 Last-Minute Summer Getaways</>,
    subheading: 'Don’t miss out on your ultimate summer vacay',
    datePostedString: '01/09/22',
    imageDirectory: `${IMAGE_DIR}/top-5-last-minute-summer-getaways`,
    href: '/blog/top-5-last-minute-summer-getaways',
  },
  {
    title: <>The Best 4 Camping Spots for Summer 2022</>,
    subheading: 'Get your inner outdoorsy thrill on right here in the UK',
    datePostedString: '18/08/22',
    imageDirectory: `${IMAGE_DIR}/best-4-camping-spots-for-summer-2022`,
    href: '/blog/best-4-camping-spots-for-summer-2022',
  },
  {
    title: <>Meet Our £10k Iceland Raffle Winner</>,
    subheading: 'James won a £10k holiday to the land of fire and ice',
    datePostedString: '17/03/22',
    imageDirectory: `${IMAGE_DIR}/meet-our-10k-iceland-raffle-winner`,
    href: '/blog/meet-our-10k-iceland-raffle-winner',
  },
  {
    title: <>Our Travel Wishlist for 2022</>,
    subheading: 'DAYMADE’s top 10 travel destinations',
    datePostedString: '14/01/22',
    imageDirectory: `${IMAGE_DIR}/our-travel-wishlist-for-2022`,
    href: '/blog/our-travel-wishlist-for-2022',
  },
  {
    title: <>How to Travel Sustainably with DAYMADE</>,
    subheading: 'Eco-friendly travel with minimal carbon footprint',
    datePostedString: '15/12/21',
    imageDirectory: `${IMAGE_DIR}/how-to-travel-sustainably-with-daymade`,
    href: '/blog/how-to-travel-sustainably-with-daymade',
  },
  {
    title: <>How To Be More Sustainable This Christmas</>,
    subheading: 'Our top tips for eco-friendly festivities',
    datePostedString: '26/11/21',
    imageDirectory: `${IMAGE_DIR}/how-to-be-more-sustainable-this-christmas`,
    href: '/blog/how-to-be-more-sustainable-this-christmas',
  },
  {
    title: <>Meet our £25k jackpot winner</>,
    subheading: 'We treated Harriet to a Japan-themed day in London',
    datePostedString: '22/10/21',
    imageDirectory: `${IMAGE_DIR}/meet-our-25k-prize-winner`,
    href: '/blog/meet-our-25k-prize-winner',
  },
  {
    title: <>The Best Halloween Events Around the World</>,
    subheading: 'Our top 11 spooktacular goings-on',
    datePostedString: '18/10/21',
    imageDirectory: `${IMAGE_DIR}/best-halloween-events-around-the-world`,
    href: '/blog/best-halloween-events-around-the-world',
  },
  {
    title: <>Lifestyle Prize Highlights</>,
    subheading: 'Five favourites from £25 to £10,000',
    datePostedString: '27/08/21',
    imageDirectory: `${IMAGE_DIR}/lifestyle-prize-highlights`,
    href: '/blog/lifestyle-prize-highlights',
  },
  {
    title: <>Our Positive-Impact Partnership with Much Better Adventures</>,
    subheading:
      'We’ve joined forces to bring you even more amazing trips and getaways',
    datePostedString: '21/07/21',
    imageDirectory: `${IMAGE_DIR}/daymade-x-much-better-adventures`,
    href: '/blog/daymade-x-much-better-adventures',
  },
  {
    title: (
      <>7 small business owners share what community support means to them</>
    ),
    subheading:
      'Support your favourite local business with DAYMADE and Town & City',
    datePostedString: '07/06/21',
    imageDirectory: `${IMAGE_DIR}/7-small-business-owners-share-their-thoughts-on-community-support`,
    href: '/blog/7-small-business-owners-share-their-thoughts-on-community-support',
  },
  {
    title: <>Tech Prize Highlights</>,
    subheading: '25 epic tech gadgets - what would you choose?',
    datePostedString: '18/05/21',
    imageDirectory: `${IMAGE_DIR}/tech-prize-highlights`,
    href: '/blog/tech-prize-highlights',
  },
  {
    title: <>7 Questions For Trees For The Future</>,
    subheading: 'Celebrating Earth Day — 150,280 trees planted',
    datePostedString: '22/04/21',
    imageDirectory: `${IMAGE_DIR}/7-questions-for-trees-for-the-future`,
    href: '/blog/7-questions-for-trees-for-the-future',
  },
  {
    title: <>This Is How These 4 Won A Holiday In Lockdown</>,
    subheading: '4 winners, 4 holiday plans sorted',
    datePostedString: '30/03/21',
    imageDirectory: `${IMAGE_DIR}/this-is-how-these-four-won-a-holiday-in-lockdown`,
    href: '/blog/this-is-how-these-four-won-a-holiday-in-lockdown',
  },
  {
    title: <>DAYMADE Supporting Local Businesses</>,
    subheading: 'Our Fave Local Businesses',
    datePostedString: '09/02/21',
    imageDirectory: `${IMAGE_DIR}/our-favourite-local-businesses`,
    href: '/blog/our-favourite-local-businesses',
  },
  {
    title: <>30,000 Free Event Tickets For NHS & Frontline Workers</>,
    subheading: 'thanks to new DAYMADE & Tickets for Good partnership',
    datePostedString: '12/10/20',
    imageDirectory: `${IMAGE_DIR}/30000-free-event-tickets-for-nhs-and-frontline-workers`,
    href: '/blog/30000-free-event-tickets-for-nhs-and-frontline-workers',
  },
  {
    title: <>Best Castle Stays</>,
    subheading: 'to live your best life',
    datePostedString: '05/10/20',
    imageDirectory: `${IMAGE_DIR}/best-castle-stays`,
    href: '/blog/best-castle-stays-to-live-your-best-life',
  },
  {
    title: <>Team DAYMADE's Favourites</>,
    subheading: "We're a travel-loving bunch",
    datePostedString: '22/09/20',
    imageDirectory: `${IMAGE_DIR}/team-triphunters-favourites`,
    href: '/blog/team-triphunters-favourites',
  },
  {
    title: <>5 EPIC TRIPS FOR TWO UNDER £500</>,
    subheading: "Summer 2020 isn't cancelled",
    datePostedString: '01/07/20',
    imageDirectory: `${IMAGE_DIR}/epic-trips-under-500`,
    href: '/blog/5-epic-trips-for-two-under-500-pounds',
  },
  {
    title: <>6 BEST GAY PRIDES AROUND THE WORLD</>,
    subheading: 'Marking the 50th annual Gay Pride month',
    datePostedString: '10/06/20',
    imageDirectory: `${IMAGE_DIR}/gay-pride`,
    href: '/blog/6-best-gay-prides-around-the-world',
  },
  {
    title: <>HAPPY EARTH DAY</>,
    subheading: '19,800 trees planted with Trees for the Future partnership',
    datePostedString: '22/04/20',
    imageDirectory: `${IMAGE_DIR}/earth-day`,
    href: '/blog/19800-trees-planted-with-partnership-triphunters-and-trees-for-the-future',
  },
  {
    title: <>5 FUN THINGS TO DO AT HOME</>,
    subheading: 'Keep yourself entertained',
    datePostedString: '27/03/20',
    imageDirectory: `${IMAGE_DIR}/fun-things-at-home`,
    href: '/blog/5-fun-things-to-do-at-home',
  },
  {
    title: <>A note to our DAYMADE community regarding COVID-19</>,
    subheading: 'What you need to know about our prizes',
    datePostedString: '17/03/20',
    imageDirectory: `${IMAGE_DIR}/covid-19-impact`,
    href: '/blog/a-note-to-our-triphunters-community-regarding-covid-19',
  },
  {
    title: <>6 Places Around The World</>,
    subheading: 'to embrace your inner influencer',
    datePostedString: '29/11/19',
    imageDirectory: `${IMAGE_DIR}/inner-influencer`,
    href: '/blog/6-places-around-the-world-to-embrace-your-inner-influencer',
  },
  {
    title: <>8 Things To Do In Bali</>,
    subheading: 'get off the beaten track',
    datePostedString: '16/12/19',
    imageDirectory: `${IMAGE_DIR}/beaten-track-bali`,
    href: '/blog/8-things-to-do-off-the-beaten-track-in-bali',
    backgroundPosition: 'bottom',
  },
  {
    title: <>8 Places To Visit In Iceland</>,
    subheading: 'get off the beaten track',
    datePostedString: '16/12/19',
    imageDirectory: `${IMAGE_DIR}/beaten-track-iceland`,
    href: '/blog/8-off-the-beaten-track-places-to-visit-in-iceland',
  },
  {
    title: <>9 Places To Visit In Japan</>,
    subheading: 'get off the beaten track',
    datePostedString: '16/12/19',
    imageDirectory: `${IMAGE_DIR}/beaten-track-japan`,
    href: '/blog/9-off-the-beaten-track-places-to-visit-in-japan',
  },
  {
    title: <>9 Things You Didn't Know You Could Do In The UK</>,
    subheading: 'get off the beaten track',
    datePostedString: '16/12/19',
    imageDirectory: `${IMAGE_DIR}/uk`,
    href: '/blog/9-things-you-didnt-know-you-could-do-in-the-uk',
  },
  {
    title: <>8 AirBnB Experiences for Foodies</>,
    subheading: "TripHunter's Ultimate Guide",
    datePostedString: '16/12/19',
    imageDirectory: `${IMAGE_DIR}/airbnb-foodies`,
    href: '/blog/ultimate-guide-to-8-airbnb-experiences-for-foodies',
  },
];
