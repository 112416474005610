import { ASSET_PREFIX } from 'utils/constants';

const QuoteCard = ({ quoteText, name }) => (
  <article className='card'>
    <img
      src={`${ASSET_PREFIX}/images/landing/stronger-variant/quotes.svg`}
      alt=""
      className='quoteMarks'
    />

    <p className='quoteText'>
      {quoteText}
    </p>

    <p className='name'>
      {name}
    </p>

    <img
      src={`${ASSET_PREFIX}/images/landing/stronger-variant/tp-stars.svg`}
      alt=""
      className='tpStars'
    />

    <style jsx>
      {`
        .card {
          padding: 2em 1em;
          background-color: white;
          border-radius: 3px;
          margin: 0.5em;
          flex: 1;
          min-width: 200px;
          max-width: 230px;
          box-shadow:
            0 -1px 2px rgba(0,0,0,0.12), 
            0 2px 2px rgba(0,0,0,0.12), 
            0 4px 4px rgba(0,0,0,0.12), 
            0 8px 8px rgba(0,0,0,0.12);
          display: flex;
          border: 1px solid #e1e1e1;
          flex-direction: column;
          text-align: center;
        }
        
        .quoteMarks {
          height: 30px;
          margin-bottom: 1em;
        }
        
        .quoteText {
          font-weight: 800;
        }
        
        .name {
          font-style: italic;
          font-size: 0.9em;
        }
        
        .name::before {
          content: '– ';
        }
        
        .tpStars {
          height: 20px;
          margin-top: auto;
          justify-self: flex-end;
        }
      `}
    </style>
  </article>
);

export default QuoteCard;
