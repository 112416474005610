import Link from "next/link";
import FaqCard from 'components/FaqCard';
import Chevron from "components/Chevron";
import Subheading from 'components/Subheading';
import Separator from 'components/Separator';
import PaddedMaxWidthContainer from 'components/layout/PaddedMaxWidthContainer';
import { useState } from 'react';

const FaqSection = ({ faqs }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <PaddedMaxWidthContainer style={{ maxWidth: 1000, width: '100%', padding: '3em 3em 0', marginBottom: 0 }}>
      <div className='headingContainer' onClick={() => setExpanded(!expanded)}>
        <Subheading id="socialHeading" underlineColor='transparent' style={{ borderLeft: '8px solid #B9F5E6', textAlign: 'left', paddingLeft: '0.4em', marginBottom: '1em' }}>
          A Few <strong>FAQs</strong>
        </Subheading>

        <Chevron
          direction='down'
          reversed={expanded}
          style={{
            marginRight: '1.5em',
          }}
        />
      </div>

      { expanded && (
        <>
          { faqs.map((faq, i) =>
            <FaqCard
              key={i}
              question={faq.question}
              answer={faq.answer}
              hideSeparator
              style={{
                margin: '0.75em 0 0',
                background: 'revert',
                boxShadow: 'revert',
                borderTop: '2px solid #e1e1e1',
                borderRadius: 'revert',
                paddingLeft: '-2em',
              }}
            />
          )}

          <Link href='/faq'>
            <a className='seeMoreButton'>
              View more FAQs
              <Chevron
                direction='right'
                style={{
                  fontSize: '1em',
                  marginLeft: '0.7em',
                  height: 'auto',
                  display: 'inline-block'
                }}
              />
            </a>
          </Link>
        </>
      )}

      <Separator
        style={{
          width: '100%',
          margin: '2em auto',
          maxWidth: 928,
        }}
      />

      <style jsx>
        {`
          .headingContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            width: 100%;
          }
          
          .seeMoreButton {
            font-size: 1.1em;
            font-weight: 700;
            font-style: italic;
            margin: 2em;
          }
        `}
      </style>
    </PaddedMaxWidthContainer>
  );
};

export default FaqSection;
