import { ReactNode, useEffect, useRef } from "react";

interface IVideoHeroProps {
  mp4Src: string;
  jpegPosterSrc?: string;
  opacity: number;
  children?: ReactNode;
  height?: number
}

const VideoHero = ({
  mp4Src,
  jpegPosterSrc = "",
  opacity,
  children,
  height,
}: IVideoHeroProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    videoRef.current?.load();
  }, [mp4Src]);

  return (
    <div className="videoHero fullWidth flexCentered">
      <video
        ref={videoRef}
        className="video"
        autoPlay
        loop
        muted
        playsInline
        width="100%"
        height="auto"
        poster={jpegPosterSrc}
        preload="auto"
      >
        <source src={mp4Src} type="video/mp4" />
      </video>

      {children && (
        <div className="childrenContainer fullWidth flexCentered">
          {children}
        </div>
      )}
      <style jsx>
        {`
          .videoHero {
            position: relative;
            overflow: hidden;
            background: black;
          }

          .video {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: ${opacity || 1};
          }

          .childrenContainer {
            position: relative;
            ${height ? `height: ${height}px` : "min-height: 100px"};
          }
        `}
      </style>
    </div>
  );
};

export default VideoHero;
