import { performFetchRequest } from './common';

export const getReferrals = async (jwt) => {
  return await performFetchRequest('/referrals', {
    headers: {
      'x-access-token': jwt
    }
  });
};

export const incrementReferralLinkCopyCountForUser = async (jwt) => {
  if (!jwt) return;

  return await performFetchRequest('/referrals/increment-referral-link-copy-count', {
    method: 'POST',
    headers: {
      'x-access-token': jwt,
    },
  });
};
