import RoundedButton from 'components/RoundedButton';
import { scrollToSection } from "utils/common";

const EnterNowCTAScrollToPlans = () => (
  <RoundedButton
    className="enterNowCTAScrollToPlans"
    onClick={() => scrollToSection('#entryMethodSelectionSection')}
  >
    Enter now
  </RoundedButton>
);

export default EnterNowCTAScrollToPlans;
