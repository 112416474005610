import RoundedButton from 'components/RoundedButton';
import { ASSET_PREFIX } from 'utils/constants';
import Subheading from 'components/Subheading';
import Link from 'next/link';
import GreenPageSection from 'components/GreenPageSection';

const WhyEnterSection = ({ ctaText, ctaHandler, premiumView, pullDownAboveSection }) => {
  return (
    <GreenPageSection additionalClassName="whyEnterSection">
      <Subheading underlineColor='transparent' textShadowColor='white' style={{ margin: 0 }}>
        Why {premiumView ? '' : 'Enter '}<strong>DAYMADE?</strong>
      </Subheading>

      <p style={{ margin: '2em 1em 0', textAlign: 'center' }}>
        Still looking for your excuse to enter DAYMADE's epic online prize draw and {''}
        <Link href='/prizes/trips-and-stays'>
          <a style={{ textDecoration: 'underline', whiteSpace: 'break-spaces' }}>
            win a holiday
          </a>
        </Link> or {''}
        <Link href='/prizes'>
          <a style={{ textDecoration: 'underline', whiteSpace: 'break-spaces' }}>
            epic prize
          </a>
        </Link>?
      </p>

      <div className="whyEnterGrid">
        {/* Temporarily hidden */}
        {/*<div className="whyEnterGridItem">*/}
        {/*  <img className="icon" src={`${ASSET_PREFIX}/images/landing/stronger-variant/fingers-crossed-circle.svg`} alt="Fingers crossed" />*/}
        {/*  <p className="text">*/}
        {/*    7X better chances than EuroMillions*/}
        {/*  </p>*/}
        {/*  <p>*/}
        {/*    With better chances to win prizes than EuroMillions, we know where we’d place our bets.*/}
        {/*  </p>*/}
        {/*</div>*/}

        <div className="whyEnterGridItem">
          <img className="icon" src={`${ASSET_PREFIX}/images/landing/stronger-variant/winner-circle.svg`} alt="DAYMADE winner celebrating" />
          <p className="text">
            2,000+ winners every week since January
          </p>
          <p>
            Our DAYMADE {''}
            <Link href='/winners'>
              <a style={{ textDecoration: 'underline', whiteSpace: 'break-spaces' }}>
                community of winners
              </a>
            </Link> and players grows every week. Don’t miss out!
          </p>
        </div>

        <div className="whyEnterGridItem">
          <img className="icon" src={`${ASSET_PREFIX}/images/landing/stronger-variant/trophy-circle.svg`} alt="Trophy" />
          <p className="text">
            Over 200 EPIC prizes
          </p>
          <p>
            Choose your appropriate prize from our extensive {''}
            <Link href='/prizes'>
              <a style={{ textDecoration: 'underline', whiteSpace: 'break-spaces' }}>
                selection of prizes
              </a>
            </Link> in four different categories.
          </p>
        </div>

        <div className="whyEnterGridItem">
          <img className="icon" src={`${ASSET_PREFIX}/images/landing/stronger-variant/plant-a-tree-circle.svg`} alt="Plant a tree" />
          <p className="text">
            Plant a tree with every purchase
          </p>
          <p>
            Support our commitment to the environment. Every purchase, we pledge to {''}
            <Link href='/giving-back'>
              <a style={{ textDecoration: 'underline', whiteSpace: 'break-spaces' }}>
                give back and plant a tree
              </a>
            </Link>
            .
          </p>
        </div>
      </div>

      {ctaText && (
        <RoundedButton onClick={ctaHandler} style={{ minWidth: '300px', marginTop: '2em', textShadow: 'none' }}>
          {ctaText}
        </RoundedButton>
      )}

      <style jsx>
        {`
        :global(.whyEnterSection) {
          margin-top: ${pullDownAboveSection ? '-350px' : 0};
          padding-top: ${pullDownAboveSection ? '300px' : '4em'};
        }
        
        .whyEnterGrid {
          display: grid;
          justify-content: center;
          align-items: start;
          grid-template-columns: 1fr; /* in 4 rows */
          gap: 2em;
          margin: 1em 1em 2em;
        }
        
        .whyEnterGridItem {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        }
        
        .icon {
          width: 6em;
          height: 7em;
          margin: 1em 0;
        }
        
        .text {
          font-weight: 800;
          font-size: 1.1em;
          text-shadow: 2px 2px white;
        }
        
        @media(min-width: 450px) {
          .whyEnterGrid {
            grid-template-columns: 1fr 1fr; /* in 2 rows */
          }
        }
        
        @media(min-width: 750px) {
          .whyEnterGrid {
            gap: 3em;
            grid-template-columns: 1fr 1fr 1fr; /* all in one row */
          }
        }

        // @media(min-width: 950px) {
        //   .whyEnterGrid {
        //     grid-template-columns: 1fr 1fr 1fr 1fr; /* all in one row */
        //   }
        // }
        `}
      </style>
    </GreenPageSection>
  );
}

export default WhyEnterSection;
