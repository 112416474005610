import GreenPageSection from 'components/GreenPageSection';
import Subheading from 'components/Subheading';
import HowItWorksSteps from 'components/common/HowItWorksSteps';

const HowItWorksScrollingGreenPageSection = () => {
  return (
    <div className="howItWorksScrollingGreenPageSection fullWidth">
      <GreenPageSection>
        <Subheading>
          How it <strong>works</strong>
        </Subheading>

        <HowItWorksSteps horizontalScrollOnMobile />
      </GreenPageSection>
    </div>
  );
};

export default HowItWorksScrollingGreenPageSection;
