import { ASSET_PREFIX } from 'utils/constants';
import TrustpilotRatingWidget from 'components/TrustpilotRatingWidget';
import React, { useState } from 'react';
import CardList from 'components/CardList';
import Subheading from 'components/Subheading';
import RoundedButton from 'components/RoundedButton';
import { useRouter } from 'next/router';
import { EntryMethodDetails } from 'components/enter/EntryMethod';
import GreenPageSection from 'components/GreenPageSection';
import PaddedMaxWidthContainer from 'components/layout/PaddedMaxWidthContainer';
import Chevron from 'components/Chevron';
import HowToPlayPopup from 'components/enter/HowToPlayPopup';
import ReturnToTopOfPageButton from 'components/common/ReturnToTopOfPageButton';
import { useEligibilityCheck } from 'hooks/useEligibilityCheck';
import { ENTER_CTA_LINK } from 'utils/cta/CTA';

interface IEntryMethodSelectionSectionProps {
  withTrustpilotWidget?: boolean;
}
const EntryMethodSelectionSection = ({
  withTrustpilotWidget = false,
}: IEntryMethodSelectionSectionProps) => {
  const router = useRouter();
  const { eligibleForIntroductoryOffer } = useEligibilityCheck();
  const [howToPlayPopupVisible, setHowToPlayPopupVisible] = useState(false);

  const entryMethodOptions = [
    EntryMethodDetails.PremiumSubscription,
    EntryMethodDetails.LiteSubscription,
    EntryMethodDetails.Postal,
  ];

  return (
    <>
      {howToPlayPopupVisible && (
        <HowToPlayPopup
          setShowHowToPlayPopup={setHowToPlayPopupVisible}
          customTitle={
            <>
              How it <strong>works</strong>
            </>
          }
        />
      )}

      <GreenPageSection
        id="entryMethodSelectionSection"
        additionalClassName="entryMethodSelectionSection"
        noPadding
        onClick={undefined}
      >
        {withTrustpilotWidget && (
          <TrustpilotRatingWidget
            widgetId="trustpilot-rating-widget-home-page" /* needed because some pages show more than one trustpilot logo */
            style={{ margin: '0 0 1em' }}
          />
        )}

        <PaddedMaxWidthContainer>
          <div className="flexRow">
            <Subheading className="flexRow" style={{ marginBottom: 0 }}>
              Choose your <strong>plan</strong>
            </Subheading>
            <img
              src={`${ASSET_PREFIX}/images/info-circle.svg`}
              alt="info"
              className="infoIcon"
              onClick={() => setHowToPlayPopupVisible(true)}
            />
          </div>

          <p style={{ marginTop: '0.7em', maxWidth: 400 }}>
            Select from our paid and free membership options.{' '}
            <strong>Cancel anytime</strong>
          </p>
        </PaddedMaxWidthContainer>

        <CardList id="entryMethodCardList" style={{ marginTop: 8 }}>
          {entryMethodOptions.map((entryMethodDetails) => (
            <div
              key={entryMethodDetails.name}
              className="card"
              onClick={() => {
                router.push(ENTER_CTA_LINK);
              }}
              style={{ cursor: 'pointer' }}
            >
              {entryMethodDetails?.withFirstEntryFreeBanner &&
                eligibleForIntroductoryOffer && (
                  <img
                    src={`${ASSET_PREFIX}/images/landing/diagonal-banner-50-off-1st-entry.png`}
                    className="firstEntryFreeBanner"
                    alt="50% off 1st entry"
                  />
                )}

              <div className="headingContainer">
                <Subheading
                  className="overrideColourScheme"
                  underlineColor={entryMethodDetails.underlineGlowColor}
                  style={{ margin: 0 }}
                >
                  <strong>{entryMethodDetails.name}</strong>
                </Subheading>
              </div>

              <p className="entryMethodDescription ignoreGlobalStyles">
                {entryMethodDetails.subheading}
              </p>

              <RoundedButton style={{ minWidth: 200, marginTop: '1em' }}>
                {entryMethodDetails.buttonText}
              </RoundedButton>
            </div>
          ))}
        </CardList>

        <div
          className="tryForFreePrompt flexRow"
          onClick={() =>
            router.push(ENTER_CTA_LINK)
          }
        >
          <p>
            <strong>TRY FOR FREE</strong>
          </p>
          <p
            style={{ fontSize: '1.5em', lineHeight: '1.1em', fontWeight: 500 }}
          >
            |
          </p>
          <p className="smallText">
            toughest odds, jackpot only{' '}
            <Chevron
              inline
              direction="right"
              style={{ fontSize: '1em' }}
              reversed={undefined}
            />
          </p>
        </div>

        <ReturnToTopOfPageButton />

        <style jsx>
          {`
            .card {
              flex: 1;
              min-width: 280px;
              padding: 4em 2.5em;
              background: white;
              border-radius: 10px;
              box-shadow: 0 2px 6px #55555565;
              position: relative;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin: 1em;
            }

            .firstEntryFreeBanner {
              position: absolute;
              left: 0;
              top: 0;
              width: 90px;
            }

            .headingContainer {
              font-size: 1.5em;
            }

            :global(.entryMethodSelectionSection .enterByPost) {
              margin: 3em 0 1em;
            }

            @media (max-width: 1100px) {
              .headingContainer {
                font-size: 1.2em;
              }
            }

            .entryMethodDescription {
              font-size: 1.25em;
              font-weight: 800;
              text-align: center;
              max-width: 190px;
              margin: 0.8em;
            }

            :global(#entryMethodSelectionSection .entryMethodDescription span) {
              display: block;
            }

            .featureList {
              margin: 0 1em 1em;
              flex-grow: 1;
              text-align: center;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }

            .feature {
              font-weight: 700;
              color: #9b9b9b;
              margin: 0.3em 0;
            }

            .infoIcon {
              align-self: flex-start;
              width: 25px;
              padding: 5px;
              cursor: pointer;
            }

            .extraInfo {
              margin: 0.3em 0 0.5em;
            }

            .stylisedBulletPoint {
              font-size: 0.9em;
              margin-bottom: 0.5em;
            }

            .stylisedBulletPoint img {
              width: 18px;
              vertical-align: middle;
              margin-right: 0.6em;
            }

            .tryForFreePrompt {
              margin: 2em 0 1em;
              padding: 0.25em;
              cursor: pointer;
            }

            .tryForFreePrompt p {
              margin: 0 0.2em;
            }

            .tryForFreePrompt .smallText {
              font-size: 0.9em;
            }

            @media (min-width: 700px) {
              .stylisedBulletPoint {
                font-size: 1em;
              }

              .tryForFreePrompt .smallText {
                font-size: 1em;
              }
            }

            @media (min-width: 800px) {
              .entryMethodDescription {
                font-size: 1.6em;
                max-width: 220px;
              }
            }

            @media (min-width: 1000px) {
              :global(.card) {
                margin: 1em;
              }
            }
          `}
        </style>
      </GreenPageSection>
    </>
  );
};

export default EntryMethodSelectionSection;
