export const getCtaText = (
  subscriberStatus: 'FREEMIUM' | 'LITE' | 'PREMIUM' | null
) => {
  if (subscriberStatus) {
    switch (subscriberStatus) {
      case 'FREEMIUM':
      case 'LITE':
        return 'GO PREMIUM';

      case 'PREMIUM':
      default:
        return null;
    }
  }

  return 'ENTER NOW';
};
