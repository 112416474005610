import { colors } from 'utils/constants';
import Separator from 'components/Separator';
import GreenPageSection from 'components/GreenPageSection';
import Subheading from 'components/Subheading';

const WinnersAndTreesSection = ({ treeCount }) => {
  return (
    <GreenPageSection additionalClassName="winnersAndTreesSection">
      <div className="statContainer">
        <Subheading Tag="div">
          <strong>tThousands of winners every week</strong>
        </Subheading>
      </div>

      <Separator
        style={{
          width: 2,
          height: '100%',
          background: colors.primaryBrandGray,
        }}
      />

      <div className="statContainer">
        <Subheading Tag="div">
          <strong>{parseInt(treeCount, 10).toLocaleString()}</strong>
        </Subheading>
        <div className="prompt">trees planted</div>
      </div>

      <style jsx>
        {`
          :global(.colourPageSection.winnersAndTreesSection) {
            flex-direction: row;
            justify-content: space-between;
          }

          .statContainer {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 1.2em;
          }

          .prompt {
            font-style: italic;
            font-weight: bold;
          }

          :global(.statContainer .subheading) {
            font-size: 2.5em;
          }

          @media (max-width: 475px) {
            .statContainer {
              font-size: 1.1em;
            }
          }

          @media (min-width: 475px) {
            :global(.statContainer .subheading) {
              font-size: 3em;
            }

            .prompt {
              font-size: 1.1em;
            }
          }

          @media (min-width: 1000px) {
            :global(.statContainer .subheading) {
              font-size: 4em;
            }

            .prompt {
              font-size: 1.4em;
            }
          }
        `}
      </style>
    </GreenPageSection>
  );
};

export default WinnersAndTreesSection;
