import Link from "next/link";
import RotatingImages from 'components/RotatingImages';
import PrizeCategoriesImageGallery from 'components/common/PrizeCategoriesImageGallery';
import Subheading, { SubheadingColourScheme } from 'components/Subheading';
import CardWithBackgroundImage from 'components/CardWithBackgroundImage';
import React from 'react';
import { ASSET_PREFIX } from 'utils/constants';
import EnterNowCTAScrollToPlans from 'components/landing/OmazeVariant/EnterNowCTAScrollToPlans';

const InfoWithImages = ({ images, imageComponent, desktopShowInfoOnRHS, backgroundColour, children }) => (
  <div className="infoWithImages">
    <div className="infoContainer flexCentered">
      { children }
    </div>

    <div className="imageGalleryContainer">
      { imageComponent ?? <RotatingImages images={images} /> }
    </div>

    <style jsx>
      {`
        .infoWithImages {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr;
        }
        
        .infoContainer {
          padding: 12% 6%;
          background-color: ${backgroundColour || 'white'};
        }
        
        :global(.infoContainer *) {
          text-align: center;
        }
        
        :global(.imageGalleryContainer .cardWithBackgroundImage) {
          min-height: 220px;
          align-items: center;
        }
        
        @media(min-width: 700px) {
          .infoWithImages {
            grid-template-columns: 1fr 1fr 0;
          }
          
          .infoContainer {
            padding: ${desktopShowInfoOnRHS ? '15% 10% 15% 7%' : '15% 7% 15% 10%'};
            grid-row: 1;
            grid-column: ${desktopShowInfoOnRHS ? '2' : '1'};
            text-align: left;
          }
          
          :global(.infoWithImages .infoContainer *) {
            align-self: flex-start;
          }
          
          :global(.imageGalleryContainer .prizeCategoriesImageGallery),
          :global(.imageGalleryContainer *:not(.image-gallery-bullets):not(.categoryHeading)) {
            height: 100%;
          }
          
          :global(.infoContainer *) {
            text-align: left;
          }
          
          .imageGalleryContainer {
            grid-row: 1;
            grid-column: ${desktopShowInfoOnRHS ? '1' : '2'};
          }
        }
      `}
    </style>
  </div>
);

const InfoGrid = ({ treesPlantedCount }) => {
  return (
    <div className="infoGrid">
      <InfoWithImages
        desktopShowInfoOnRHS
        imageComponent={<PrizeCategoriesImageGallery />}
      >
        <Subheading
          underlineColor="transparent"
          style={{ marginBottom: 0 }}
        >
          Win prizes
        </Subheading>
        <Subheading style={{ marginTop: 0, marginBottom: '0.5em' }}>
          every <strong>week</strong>
        </Subheading>

        <p>
          Fancy chasing the Northern Lights in Iceland or soaking up some sun
          with your family in the Maldives? Or is a California roadtrip with
          your bestie more your thing?
        </p>

        <p>
          Travel plans already sorted? No worries! We got you covered with a
          great selection of prizes (big and small) from your favourite
          lifestyle, fashion, beauty and tech brands.
        </p>

        <p>
          Just enter DAYMADE's weekly prize draw for a chance to win and choose
          from 250+ prizes. Join the fun every Tuesday & Friday at 5pm.
        </p>

        <EnterNowCTAScrollToPlans />
      </InfoWithImages>

      <InfoWithImages
        backgroundColour="#F6F6F6"
        images={[
          {
            renderItem: () => (
              <CardWithBackgroundImage
                key="plant-trees"
                imageDirectory="/landing/omaze-variant"
                imageFname="plant-trees-bg"
              >
                <div className="flexCentered fullWidth">
                  <Subheading
                    id="treesPlantedCount"
                    className="categoryHeading"
                    colourScheme={
                      SubheadingColourScheme.WHITE_TEXT_BLACK_SHADOW
                    }
                  >
                    <strong>
                      {parseInt(treesPlantedCount, 10).toLocaleString()}
                    </strong>
                  </Subheading>
                  <img
                    id="trees-planted-to-date"
                    className="categoryHeading"
                    src={`${ASSET_PREFIX}/images/landing/omaze-variant/trees-planted-to-date.svg`}
                    alt="trees plant to date"
                  />
                </div>
              </CardWithBackgroundImage>
            ),
          },
        ]}
      >
        <Subheading style={{ marginBottom: '0.5em' }}>
          Plant <strong>trees</strong>
        </Subheading>

        <p>
          We’ve partnered with{' '}
          <a
            href="https://edenprojects.org"
            target="_blank"
            rel="noreferrer"
          >
            Eden Reforestation Projects
          </a>{' '}
          who will plant a tree with every purchase made on our site.
        </p>

        <p>
          Tree planting not only helps the planet and future generations, but it
          also immediately impacts the lives of families in need.{' '}
          <Link href="/giving-back">
            <a
              target="_blank"
              rel="noreferrer"
            >
              See how
            </a>
          </Link>
        </p>

        <EnterNowCTAScrollToPlans />
      </InfoWithImages>

      <style jsx>
        {`
          .infoGrid {
            display: grid;
            grid-template-columns: 1fr;
          }

          :global(.infoGrid .enterNowCTAScrollToPlans) {
            width: 60%;
            max-width: 250px;
            margin-top: 2%;
          }

          #trees-planted-to-date {
            min-width: 225px;
            width: 50%;
            max-width: 250px;
          }

          :global(.infoGrid #treesPlantedCount) {
            font-size: 4.5em;
            margin-bottom: 0.1em;
          }

          @media (min-width: 700px) {
            .infoGrid {
              grid-template-rows: 1fr 1fr;
            }

            :global(.infoGrid #treesPlantedCount) {
              font-size: 5.2em;
            }

            #trees-planted-to-date {
              max-width: 350px;
            }
          }

          @media (min-width: 1100px) {
            :global(.infoGrid #treesPlantedCount) {
              font-size: 7em;
            }
          }
        `}
      </style>
    </div>
  );
}

export default InfoGrid;
