import React from 'react';
import { ASSET_PREFIX } from 'utils/constants';
import { scrollToSection } from 'utils/common';

const ReturnToTopOfPageButton = ({ onClickReturnToTop = () => scrollToSection('#top-of-page') }) => {
  return (
    <div style={{ marginTop: '2em', cursor: 'pointer' }} onClick={onClickReturnToTop}>
      <img
        src={`${ASSET_PREFIX}/images/landing/omaze-variant/return-to-top-icon.svg`}
        alt="Up arrow"
        style={{ width: '40px', margin: '0.5em 0' }}
      />

      <p style={{ textTransform: 'uppercase', fontWeight: 700 }}>
        Return to top
      </p>
    </div>
  );
};

export default ReturnToTopOfPageButton;
