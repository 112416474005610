import Subheading from 'components/Subheading';
import RoundedButton from 'components/RoundedButton';
import PaddedMaxWidthContainer from 'components/layout/PaddedMaxWidthContainer';
import { ASSET_PREFIX } from 'utils/constants';
import Link from 'next/link';
import CardList from 'components/CardList';
import React from 'react';
import CardWithBackgroundImage from 'components/CardWithBackgroundImage';
import QuoteCard from 'components/landing/StrongerVariant/WinnersSection/QuoteCard/QuoteCard';

const Quote = ({ author, children }) => {
  return (
    <Link href="https://www.trustpilot.com/review/daymade.co.uk" passHref>
      <div className="quote">
        <img
          src={`${ASSET_PREFIX}/images/landing/omaze-variant/quote-marks.svg`}
          alt="Quote marks"
          width="20%"
          className="quoteMarks"
        />
        <div className="message">
          "{children}"
        </div>
        <div className="author">
          - {author}
        </div>
        <img
          src={`${ASSET_PREFIX}/images/landing/stronger-variant/trustpilot-5-stars.svg`}
          alt="5 stars on Trustpilot"
          className='trustpilotLogo'
        />
        <style jsx>
          {`
            .quote {
              cursor: pointer;
              text-align: center;
              flex: 1;
              margin: 1em 1em 2em 0;
              width: 100%;
              padding: 0 10%;
            }
            
            .message {
              font-size: 1.2rem;
              font-weight: 700;
            }
            
            .author {
              font-style: italic;
              font-size: 1rem;
              font-weight: 600;
              margin: 0.6em 0;
            }
            
            .trustpilotLogo {
              width: 80px;
              margin-top: 1em;
            }
            
            .quoteMarks {
              margin-bottom: 1.5em;
              min-width: 60px;
              max-width: 80px;
            }
            
            @media(min-width: 700px) {
              .quote {
                max-width: 250px;
                padding: 0;
              }
            }
          `}
        </style>
      </div>
    </Link>
  );
};

const WinnerContentSection = ({ onCtaClick }) => (
  <div className="winnerContentSection fullWidth flexCentered">
    <CardWithBackgroundImage
      id="winner-grid-upper"
      imageDirectory="/landing/omaze-variant/"
      imageFname="winner-grid-upper"
    >
      <div className="aspectRatio" />
    </CardWithBackgroundImage>

    <PaddedMaxWidthContainer additionalClassName="quotesSection">
      <Subheading underlineColor="transparent" style={{ marginBottom: 0 }}>
          Thousands of winners every week
      </Subheading>

      <p>
        Will you be next?
      </p>

      <CardList id='reviewList' style={{ margin: '0 auto 1em', width: '100%', padding: '1em' }}>
        {[
          {
            quoteText: "Out of all the draws so far, I have only had 1 that I didn't win on",
            name: "Naomi",
          },
          {
            quoteText: "Thank you Daymade for being honest, fun and adding a bit of sparkle to the week!!",
            name: "Karin",
          },
          {
            quoteText: "I managed to win vouchers for £150 twice & even one of the £2500 prizes 🎉",
            name: "Lussy",
          },
          {
            quoteText: "I’ve never won anything before but I’ve won 3 times!",
            name: "Misha",
          },
        ].map((quote, i) =>
          <QuoteCard
            key={i}
            quoteText={quote.quoteText}
            name={quote.name}
          />
        )}
      </CardList>

      <RoundedButton onClick={onCtaClick} style={{ width: 220, marginTop: '2em' }}>
        Enter Now
      </RoundedButton>
    </PaddedMaxWidthContainer>

    <CardWithBackgroundImage
      id="winner-grid-lower"
      imageDirectory="/landing/omaze-variant/"
      imageFname="winner-grid-lower"
    >
      <div className="lowerGridAspectRatio" />
    </CardWithBackgroundImage>

    <style jsx>
      {`
        :global(.winnerContentSection .quotesSection) {
          margin: 4em auto;
        }
        
        :global(.winnerContentSection #winner-grid-upper) {
          min-height: 100px;
        }
        
        .aspectRatio {
          padding-top: 10%;
        }
        
        :global(.winnerContentSection #winner-grid-lower) {
          min-height: 200px;
        }
        
        .lowerGridAspectRatio {
          padding-top: 30%;
        }
        
        @media(min-width: 700px) {
          :global(.winnerContentSection .quotesSection) {
            margin: 5em auto;
          }
        }
      `}
    </style>
  </div>


);

export default WinnerContentSection;
