// @ts-nocheck
import PopupWithDimmedBackground from 'components/PopupWithDimmedBackground';
import Subheading from 'components/Subheading';
import { EntryMethodDetails } from 'components/enter/EntryMethod';
import HowItWorksSteps from 'components/common/HowItWorksSteps';
import RoundedButton from 'components/RoundedButton';
import React, { ReactNode } from 'react';

interface IHowToPlayPopupProps {
  entryMethod?: string
  setShowHowToPlayPopup: (show: boolean) => void
  customTitle?: ReactNode
}

const HowToPlayPopup = ({
  entryMethod,
  setShowHowToPlayPopup,
  customTitle,
}: IHowToPlayPopupProps) => {
  const entryMethodDetails = EntryMethodDetails[entryMethod];

  return (
    <PopupWithDimmedBackground
      additionalClassName="howToPlayPopup"
      onClose={() => setShowHowToPlayPopup(false)}
    >
      <Subheading
        underlineColor={
          entryMethod ? entryMethodDetails?.underlineGlowColor : null
        }
      >
        {customTitle ?? (
          <>
            How to <strong>play</strong>
          </>
        )}
      </Subheading>
      <HowItWorksSteps
        subscriptionType={entryMethodDetails?.subscriptionType}
        keepMobileLayoutOnDesktop
      />
      <RoundedButton onClick={() => setShowHowToPlayPopup(false)}>
        Got it
      </RoundedButton>
      <style jsx>
        {`
          :global(.howToPlayPopup .roundedButton) {
            width: 100%;
            max-width: 300px;
            margin-top: 1.5em;
          }
        `}
      </style>
    </PopupWithDimmedBackground>
  );
};

export default HowToPlayPopup;
