import { ASSET_PREFIX, colors } from 'utils/constants';
import s from './SignUpAndSaveSection.module.css';
import RoundedButton, { ColorStyle } from 'components/RoundedButton';
import { signUpForNewsletterAndDiscount } from 'api/auth';
import {
  fetchUtmParamsJoinedWithFbDataFromLocalStorage,
  identifyUser,
  trackMailingListSignup,
} from 'utils/analytics/analytics';
import { useState } from 'react';
import SectionTitle from 'components/landing/StrongerVariant/SectionTitle/SectionTitle';
import svStyles from 'components/landing/StrongerVariant/StrongerVariant.module.css';
import Link from 'next/link';
import ReferralLink from 'components/referrals/ReferralLink';
import GreenPageSection from 'components/GreenPageSection';
import Subheading from 'components/Subheading';

export const SectionType = {
  SIGN_UP_AND_SAVE: 0,
  REFER_A_FRIEND: 1,
};

const SignUpAndSaveSection = ({ sectionType = 0 }) => {
  const [email, setEmail] = useState('');
  const [successful, setSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <GreenPageSection additionalClassName={`signUpAndSaveSection${sectionType === SectionType.REFER_A_FRIEND && ' referAFriend'}`}>
      <div className="signUpAndSaveSection fullWidth flexCentered">
        <div className="flexItem title">
          { sectionType === SectionType.REFER_A_FRIEND
            ? (
              <>
                <Subheading className="mobile">
                  Refer
                  a <strong>friend</strong>
                  <img src={`${ASSET_PREFIX}/images/landing/stronger-variant/grey-heart.svg`} className={s.greyHeartIcon} alt="" />
                </Subheading>
                <Subheading className="desktop">
                  Refer
                  <img src={`${ASSET_PREFIX}/images/landing/stronger-variant/grey-heart.svg`} className={s.greyHeartIcon} alt="" />
                  a <strong>friend</strong>
                </Subheading>
              </>
            ) : (
              <SectionTitle className={s.sectionTitle} style={{ justifyContent: 'left' }}>
                <div className={svStyles.whiteShadow}>Sign</div>
                <div className={svStyles.whiteShadow}>up</div>
                <div className={svStyles.whiteShadow}>&</div>
                <div className={`${svStyles.whiteShadow} ${svStyles.outlineText}`}>save</div>
              </SectionTitle>
            )}
        </div>

        <div className={`flexItem description ${svStyles.sectionText}`} style={{ fontWeight: 600 }}>
          { sectionType === SectionType.REFER_A_FRIEND
            ? (
              <>Treat your friends to <strong>30% off</strong> their first purchase and <Link href="/account/referrals"><a style={{ fontWeight: 800, margin: 0, textDecoration: 'underline' }}>earn rewards</a></Link> along the way!</>
            ) : (
              <>Sign up to our newsletter for <b>10% off</b> your 1st purchase</>
            ) }
        </div>

        { sectionType === SectionType.SIGN_UP_AND_SAVE && (
          <div className={`flexItem ${s.mailingListSignupSection}`}>
            {!successful
              ? (
                <div className={s.inputContainer}>
                  <input
                    placeholder="Email Address"
                    type="email"
                    autoComplete="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    style={{ width: '100%' }}
                  />

                  <RoundedButton
                    id={s.signUpButton}
                    disabled={!email || email.length < 3}
                    loading={loading}
                    colorStyle={ColorStyle.Gray}
                    onClick={async () => {
                      setLoading(true);
                      const utmParams = fetchUtmParamsJoinedWithFbDataFromLocalStorage();
                      const res = await signUpForNewsletterAndDiscount('STRONGER_VARIANT_HOME_PAGE', email, '', utmParams);
                      setLoading(false);
                      if (res.successful === true) {
                        setSuccessful(true);
                        identifyUser({ emailAddress: email });
                        trackMailingListSignup(email);
                      }
                    }}
                  >
                    Sign Up
                  </RoundedButton>
                </div>
              )
              : (
                <p className={svStyles.sectionText} style={{ textAlign: 'center', marginTop: '1em' }}>
                  <b>Great, you're signed up!</b>
                </p>
              )}
          </div>
        )}

        { sectionType === SectionType.REFER_A_FRIEND && (
          <ReferralLink
            btnText="COPY CODE"
            referralLinkStyle={{ background: 'none', border: 'none', borderRadius: '0', padding: '5px 15px 5px 0', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '1.1em' }}
            buttonStyle={{ background: 'none', color: colors.primaryBrandGray, padding: 0, display: 'flex', alignItems: 'center', fontWeight: 800 }}
          />
        )}
      </div>

      <style jsx>
        {`
          .signUpAndSaveSection {
            max-width: 900px;
            text-align: center;
          }
          
          .flexItem {
            margin: 1em 0;
            min-width: 100px;
          }
          
          .flexItem>.title {
            text-align: left;
          }
          
          :global(.signUpAndSaveSection .subheading.mobile) {
            display: inline-block;
          }
          
          :global(.signUpAndSaveSection .subheading.desktop) {
            display: none;
            font-size: 3.3em;
            text-align: left;
          }
          
          :global(.referAFriend .description) {
            max-width: 350px;
          }
          
          :global(.referAFriend .referralLink) {
            flex-direction: row;
            justify-content: space-between;
            border-bottom: 2px solid #4a4a4a;
            margin: 2em 0 1em;
            max-width: 280px;
            font-size: 1.1em;
          }
          
          @media(min-width: 750px) {
            .signUpAndSaveSection {
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            }
            
            :global(.signUpAndSaveSection .subheading.mobile) {
              display: none;
            }
            
            :global(.signUpAndSaveSection .subheading.desktop) {
              display: inline-block;
              margin: 0;
            }
            
            .title {
              margin-right: 1em;
              max-width: 10rem;
            }
            
            :global(.referAFriend .title) {
              max-width: 12rem;
            }
            
            .description {
              text-align: left;
              max-width: 15rem;
              font-weight: 600;
            }
            
            :global(.referAFriend .description) {
              font-size: 1.3em;
              max-width: 20%;
              margin-left: 0.5em;
              margin-right: 0.5em;
            }
            
            :global(.referAFriend .referralLink) {
              margin: 0;
            }
          }
          
          @media(min-width: 800px) {
            :global(.referAFriend .description) {
              max-width: unset;
              width: 25%;
            }
          }
          
          @media(min-width: 900px) {
            .description {
              max-width: 20rem;
            }
          }
          
          @media(min-width: 1000px) {
            .title {
              max-width: 15rem;
            }
        
            :global(.referAFriend .title) {
              max-width: 15.5rem;
              margin: 0;
            }
        
            .description {
              max-width: 18rem;
            }
        
            :global(.referAFriend .description) {
              width: 33%;
            }
          }
          
          @media(min-width: 1100px) {
            .signUpAndSaveSection {
              max-width: ${sectionType === SectionType.REFER_A_FRIEND ? '1000px' : '1800px'};
            }
            
            .title {
              margin-right: 1em;
              max-width: 15rem;
            }
            
            .description {
              font-size: 1.4em;
              max-width: 22rem;
            }
          }
          
          @media(min-width: 1200px) {
            .description {
              font-size: 1.5em;
              max-width: 26rem;
            }
          }
        `}
      </style>
    </GreenPageSection>
  );
};

export default SignUpAndSaveSection;
