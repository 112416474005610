import { colors } from 'utils/constants';
import $ from 'jquery';
import RoundedButton from 'components/RoundedButton';
import { getMe } from 'api/auth';
import { getCookie } from 'utils/cookie';
import { useEffect, useState } from 'react';
import { copyToClipboard, inviteUrl } from 'utils/common';
import { incrementReferralLinkCopyCountForUser } from 'api/referrals';
import { trackEvent } from 'utils/analytics/analytics';
import { useUserStore } from 'hooks/stores/useUserStore';
import { observer } from 'mobx-react-lite';

interface IReferralLinkProps {
  btnText?: string;
  style?: React.CSSProperties;
  referralLinkStyle?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
}

const ReferralLink = observer(
  ({
    btnText = 'COPY',
    style,
    referralLinkStyle,
    buttonStyle,
  }: IReferralLinkProps) => {
    const { currentUser } = useUserStore();

    const [inviteLink, setInviteLink] = useState(
      currentUser ? inviteUrl(currentUser.referral_id.toString()) : ''
    );

    const copyInviteLink = () => {
      const successful = copyToClipboard(inviteLink);
      if (successful) {
        $('#copyButton').text('COPIED');
      }
      incrementReferralLinkCopyCountForUser(getCookie('jwt'));
      trackEvent('Referral: Copy Link');
    };

    useEffect(() => {
      (async () => {
        if (inviteLink === '') {
          const user = currentUser ?? (await getMe(getCookie('jwt', null)));
          if (user) {
            setInviteLink(inviteUrl(user.referral_id));
          }
        }
      })();
    }, []);

    return (
      <div className="referralLink container" style={{ ...style }}>
        <div id="referralLink" style={{ ...referralLinkStyle }}>
          <p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {inviteLink}
          </p>
        </div>

        <RoundedButton
          onClick={copyInviteLink}
          style={{ padding: '10px 18px', marginTop: '1em', ...buttonStyle }}
          id="copyButton"
        >
          {btnText}
        </RoundedButton>

        <style jsx>{`
          .container {
            display: flex;
            flex-direction: column;
            max-width: 100%;
          }

          #referralLink {
            font-size: 14px;
            display: flex;
            align-items: center;
            text-align: center;
            color: ${colors.primaryBrandGray};
            border: 2px solid #ccc;
            border-radius: 4px;
            padding: 10px;
            margin: 14px auto 0px auto;
            overflow-x: auto;
            white-space: nowrap;
            margin: 0;
            font-weight: 600;
          }
        `}</style>
      </div>
    );
  }
);

export default ReferralLink;
