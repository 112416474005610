import React, { useEffect, useState } from 'react';
import strongerVariantStyles from '../StrongerVariant.module.css';
import s from './TrendingThisMonthSection.module.css';
import PrizeCardWithTitleBelow from 'components/prizes/PrizeCardWithTitleBelow';
import Router from 'next/router';
import Subheading from 'components/Subheading';
import Separator from 'components/Separator';
import RoundedButton from 'components/RoundedButton';
import PlainLoadingSpinner from 'components/PlainLoadingSpinner';
import {
  fetchCategorisedFeaturedPrizes,
  fetchTrendingPrizes,
} from 'api/prizes';
import {
  getPrizeTierByValue,
  PRIZE_TIERS,
} from '../../../../constants/LottoPrizeValues';
import CardList from 'components/CardList';
import Link from 'next/link';
import Chevron from 'components/Chevron';
import { ASSET_PREFIX, colors } from 'utils/constants';
import { getFormattedJackpotBoostCost } from 'utils/common';
import PopupWithDimmedBackground from 'components/PopupWithDimmedBackground';
import WeeklyDrawPrizeTableAllPlans from 'components/WeeklyDrawPrizeTableAllPlans';

const TrendingThisMonthSection = ({
  ctaText,
  ctaHandler,
  omazeVariant = false,
}) => {
  const [loading, setLoading] = useState(true);
  const [prizes, setPrizes] = useState(null);
  const [prizeTablePopupVisible, setPrizeTablePopupVisible] = useState(false);

  const categorisePrizes = true;
  const [selectedCategory, setSelectedCategory] = useState('all');

  let prizesToShow = prizes;

  if (prizes !== null && categorisePrizes && selectedCategory !== null) {
    prizesToShow = prizes[selectedCategory];
  }

  let prizesToShowGroupedByValue = {};

  prizesToShow?.forEach((prize) => {
    const prizesForThisValue = prizesToShowGroupedByValue[prize.value] ?? [];
    prizesForThisValue.push(prize);
    prizesToShowGroupedByValue[prize.value] = prizesForThisValue;
  });

  const tiersToShow = Object.keys(prizesToShowGroupedByValue ?? []);
  tiersToShow.sort((a, b) => parseInt(b) - parseInt(a)); // Sort descending

  const prizeRows = tiersToShow.map((tierValue) => {
    const tierDetails = getPrizeTierByValue(parseInt(tierValue));
    const prizes = prizesToShowGroupedByValue[tierValue];
    return {
      tierDetails,
      prizes,
    };
  });

  const fetchFeaturedPrizes = async () => {
    if (categorisePrizes) {
      const prizes = await fetchCategorisedFeaturedPrizes();
      setPrizes(prizes);
    } else {
      const prizes = await fetchTrendingPrizes();
      setPrizes(prizes);
    }
    setLoading(false);
  };

  useEffect(fetchFeaturedPrizes, []);

  const [boostCostPerEntry, setBoostCostPerEntry] = useState(null);

  useEffect(async () => {
    if (!boostCostPerEntry) {
      const formattedBoostCostPerEntry = await getFormattedJackpotBoostCost();
      setBoostCostPerEntry(formattedBoostCostPerEntry);
    }
  }, []);

  return (
    <div
      className={`trendingThisMonthSection sectionContainer ${strongerVariantStyles.sectionContainer}`}
      id={s.trendingThisMonthSectionContainer}
      style={{ maxWidth: 1100 }}
    >
      {prizeTablePopupVisible && (
        <PopupWithDimmedBackground
          icon={`${ASSET_PREFIX}/images/gift-cards/redemption-confirmation/trophy-icon.svg`}
          onClose={() => setPrizeTablePopupVisible(false)}
        >
          <h4>Match to win</h4>

          <p>
            The amount of numbers you match in our weekly draws determines which
            prize category you can choose from.
          </p>

          <WeeklyDrawPrizeTableAllPlans />

          <RoundedButton
            onClick={() => setPrizeTablePopupVisible(false)}
            style={{ width: '75%', marginTop: '3em' }}
          >
            Got it
          </RoundedButton>
        </PopupWithDimmedBackground>
      )}

      <div
        className="paddedMaxWidthContainer"
        id={s.paddedMaxWidthContainer}
        style={{ marginBottom: 0 }}
      >
        {omazeVariant ? (
          <>
            <Subheading
              underlineColor="transparent"
              style={{ marginBottom: 0 }}
            >
              What prizes
            </Subheading>
            <div className="flexRow" style={{ position: 'relative' }}>
              <Subheading style={{ marginTop: 0 }}>
                can I <strong>win?</strong>
              </Subheading>
              <img
                src={`${ASSET_PREFIX}/images/info-circle.svg`}
                alt="info"
                className="infoIcon"
                onClick={() => setPrizeTablePopupVisible(true)}
              />
            </div>
          </>
        ) : (
          <Subheading>
            {categorisePrizes ? (
              <>
                What Prizes Can You <strong>Win?</strong>
              </>
            ) : (
              <>
                Prizes Trending This <strong>Month</strong>
              </>
            )}
          </Subheading>
        )}

        <p
          style={{
            marginLeft: '-10px',
            marginRight: '-10px',
            maxWidth: '700px',
          }}
        >
          {categorisePrizes ? (
            <>
              The more numbers you match in our weekly draw, the bigger the
              prize you can choose. Check out our collection of over 200 epic
              prizes below.
            </>
          ) : (
            <>Check out a few of this month’s most popular prizes:</>
          )}
        </p>
      </div>

      {loading || !prizesToShow ? (
        <PlainLoadingSpinner style={{ margin: '5em auto' }} />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginTop: '3em',
          }}
        >
          {prizeRows.map((prizeRow) => {
            if (
              !prizeRow?.tierDetails?.badge ||
              (!prizeRow?.tierDetails?.title &&
                !prizeRow?.tierDetails?.titleComponent)
            )
              return null;
            const boostPrize =
              prizeRow.tierDetails.key === PRIZE_TIERS.DAYMAKERboost.key;
            return (
              <div className="prizeRow" key={prizeRow.tierDetails.key}>
                <div className="header">
                  <img
                    src={prizeRow.tierDetails.badge}
                    alt=""
                    className="badgeIcon clickable"
                    onClick={() =>
                      Router.push(`/prizes?tier=${prizeRow.tierDetails.key}`)
                    }
                  />

                  <div
                    className="titleContainer flexRow"
                    style={{ flexWrap: 'wrap' }}
                  >
                    <div
                      className="clickable"
                      onClick={() =>
                        Router.push(`/prizes?tier=${prizeRow.tierDetails.key}`)
                      }
                      style={{ marginRight: '0.75em' }}
                    >
                      <p className="title">
                        {prizeRow.tierDetails.title}
                        {prizeRow.tierDetails.subtitle && (
                          <span
                            className="subtitle"
                            style={{
                              margin: 0,
                              textTransform: 'none',
                              fontWeight: 600,
                              fontStyle: 'italic',
                              fontSize: '0.85em',
                            }}
                          >
                            {prizeRow.tierDetails.subtitle}
                          </span>
                        )}
                      </p>
                    </div>
                    {/*{ boostPrize && (*/}
                    {/*  <div className="addOnBadge">*/}
                    {/*    ADD ON*/}
                    {/*  </div>*/}
                    {/*)}*/}
                  </div>

                  <p className="examples" style={{ fontStyle: 'italic' }}>
                    {prizeRow.tierDetails.examplePrizes}
                  </p>

                  <div className="roughValue">
                    <div
                      onClick={() => setPrizeTablePopupVisible(true)}
                      className="flexCentered clickable"
                    >
                      {prizeRow.tierDetails.ballMatches.indicator}
                    </div>
                  </div>

                  <div className="ballMatchesCopy flexRightAligned">
                    <div
                      onClick={() => setPrizeTablePopupVisible(true)}
                      className="flexCentered clickable"
                      style={{ height: '100%' }}
                    >
                      {prizeRow.tierDetails.ballMatches.copy}
                    </div>
                  </div>
                </div>

                <CardList
                  noDots
                  fastScroll
                  id={`${prizeRow.tierDetails.key}_prize_list`}
                  style={{
                    marginTop: 8,
                    maxWidth: '100%',
                    justifyContent: 'start',
                  }}
                >
                  {prizeRow.prizes.map((prize, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        {boostPrize && idx === 2 && (
                          <div
                            className="daymakerBoostCardContainer"
                            key="daymaker-boost-info-card"
                          >
                            <div
                              className="daymakerBoostCard flexCentered"
                              onClick={() =>
                                Router.push(
                                  `/prizes?tier=${PRIZE_TIERS.DAYMAKERboost.key}`
                                )
                              }
                            >
                              <img
                                src={`${ASSET_PREFIX}/images/prizes/white-rocket-boost-icon.png`}
                                alt=""
                              />
                              <h5>Boosted prizes</h5>
                              <p style={{ fontSize: '1em' }}>
                                Unlock these DAYMAKERboost prizes and increase
                                your jackpot to £100k by boosting your entry for{' '}
                                <strong>just {boostCostPerEntry}</strong>
                              </p>
                              <p style={{ fontWeight: 700, fontSize: '0.9em' }}>
                                *Tuesday draw only
                              </p>
                            </div>
                          </div>
                        )}
                        <PrizeCardWithTitleBelow
                          key={prize.slug}
                          prize={prize}
                          onClick={(event) => {
                            Router.push(`/prizes/details/${prize.slug}`);
                            event.preventDefault();
                          }}
                          href={`/prizes/details/${prize.slug}`}
                          withFavouriteButton={true}
                          style={{
                            textAlign: 'left',
                            maxWidth: 250,
                            minWidth: 250,
                            height: 330,
                            paddingBottom: 80,
                          }}
                          showPrizeTierBadge={false}
                        />
                      </React.Fragment>
                    );
                  })}

                  <Link
                    href={`/prizes?tier=${prizeRow.tierDetails.key}`}
                    passHref
                  >
                    <div
                      style={{
                        padding: '1em',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        flexGrow: 0,
                        margin: 'auto 1em',
                        whiteSpace: 'nowrap',
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                      }}
                    >
                      See more
                      <Chevron
                        direction="right"
                        style={{
                          marginLeft: '0.5em',
                          verticalAlign: 'middle',
                        }}
                      />
                    </div>
                  </Link>
                </CardList>
              </div>
            );
          })}
        </div>
      )}

      <Separator style={{ width: '95%', maxWidth: 980 }} />

      <p style={{ fontStyle: 'italic', maxWidth: 800, margin: '2em 1em 0' }}>
        We’re passionate about providing prizes you want to win. That’s why we
        add prizes and offer the best selection of online prizes, worth
        different values, based on your feedback. Choose from epic{' '}
        <Link href="/prizes/trips-and-stays">
          <a>holidays</a>
        </Link>
        , unforgettable{' '}
        <Link href="/prizes/experiences">
          <a>experiences</a>
        </Link>
        ,{' '}
        <Link href="/prizes/family">
          <a>family getaways</a>
        </Link>
        , your favourite{' '}
        <Link href="/prizes/tech">
          <a>tech</a>
        </Link>
        , and prizes to fit your{' '}
        <Link href="/prizes/lifestyle-and-beauty">
          <a>lifestyle</a>
        </Link>
        .
      </p>

      {ctaText && (
        <>
          <p
            style={{
              fontStyle: 'italic',
              maxWidth: 800,
              fontWeight: 'bold',
              margin: '2em 1em 0',
            }}
          >
            See a prize you like?
          </p>

          <RoundedButton
            onClick={ctaHandler}
            style={{ width: '100%', maxWidth: '300px', margin: '2em auto 0' }}
          >
            {ctaText}
          </RoundedButton>
        </>
      )}

      <style jsx>
        {`
          .prizeRow {
            margin: 0.5em 0;
          }

          .prizeRow .header {
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: 60px 1fr 0.9fr;
            grid-gap: 0 0.2em;
            align-items: center;
            margin-left: 1em;
            margin-right: 1em;
          }

          .prizeRow .badgeIcon {
            width: 50px;
            height: 50px;
            grid-column: 1 / 2;
            grid-row: 1 / 3;
          }

          .prizeRow .titleContainer {
            text-align: left;
            grid-column: 2 / 3;
            grid-row: 1 / 2;
            margin-bottom: 0;
          }

          .prizeRow .examples {
            text-align: left;
            grid-column: 2 / 4;
            grid-row: 2 / 3;
            margin-bottom: 0;
            align-self: flex-start;
            font-weight: 700;
          }

          .prizeRow .roughValue {
            grid-column: 3 / 4;
            grid-row: 1;
            display: flex;
            justify-content: flex-end;
          }

          .prizeRow .ballMatchesCopy {
            grid-column: 3 / 4;
            grid-row: 2;
            text-align: right;
            font-size: 0.8rem;
            font-weight: 700;
            color: ${colors.primaryBrandGray};
            align-self: stretch;
            line-height: 1;
          }

          .addOnBadge {
            background: #ff399e;
            border-radius: 30px;
            color: white;
            font-size: 0.7em;
            font-weight: 700;
            padding: 0.5em 1em;
          }

          .daymakerBoostCardContainer {
            text-align: left;
            max-width: 250px;
            min-width: 250px;
            height: 330px;
            padding-bottom: 80px;
            margin: 1em 0.5em 1em 0.5em;
          }

          .daymakerBoostCard {
            height: 100%;
            position: relative;
            color: white;
            border-radius: 15px;
            padding: 5px;
            cursor: pointer;
            padding: 2em;
            text-align: center;
            background-image: linear-gradient(180deg, #ff7979 0%, #ff32a2 100%);
          }

          .daymakerBoostCard img {
            width: 50px;
            height: 50px;
          }

          .daymakerBoostCard h5 {
            text-transform: none;
            letter-spacing: 0;
            font-weight: 800;
            font-size: 1.3em;
            margin: 0.75em 0 1em;
          }

          .daymakerBoostCard strong {
            font-weight: 800;
          }

          :global(.prizeRow p.title) {
            margin-bottom: 0;
            font-size: 1.2rem;
            text-transform: uppercase;
            font-weight: 900;
          }

          .clickable {
            cursor: pointer;
          }

          .infoIcon {
            align-self: flex-start;
            width: 25px;
            padding: 5px;
            cursor: pointer;
            position: absolute;
            right: -25px;
          }

          a {
            text-decoration: underline;
          }

          @media (max-width: 400px) {
            .prizeRow .header {
              grid-template-columns: 53px 1fr 1fr;
              grid-gap: 0 0.2em;
            }

            .prizeRow .badgeIcon {
              width: 45px;
              height: 45px;
            }

            :global(.prizeRow p.title) {
              font-size: 1rem;
            }

            .prizeRow .examples {
              font-size: 0.85rem;
            }

            .prizeRow .ballMatchesCopy {
              font-size: 0.7rem;
            }
          }

          @media (min-width: 700px) {
            .prizeRow {
              margin: 1em 0;
            }

            .prizeRow .header {
              grid-template-columns: 65px 1fr 1fr;
              grid-gap: 0 0.2em;
            }

            .prizeRow .badgeIcon {
              width: 55px;
              height: 55px;
            }

            :global(.prizeRow p.title) {
              font-size: 1.7em;
            }

            .prizeRow .ballMatchesCopy {
              font-size: 0.9rem;
            }
          }
        `}
      </style>
    </div>
  );
};

export default TrendingThisMonthSection;
