import { ASSET_PREFIX } from 'utils/constants';
import Link from 'next/link';
import RoundedButton from 'components/RoundedButton';
import s from './HomePageHeroSection.module.css';
import VideoHero from 'components/VideoHero';
import Subheading, { SubheadingColourScheme } from 'components/Subheading';
import { useEffect, useState } from 'react';

const HeroContent = ({ withCtaAndTreeMessage, ctaText, ctaHandler, omazeVariant }) => {
  if (omazeVariant) {
    return (
      <div className="om heroContent flexCentered">
        <div className="mobileOnly flexCentered">
          <Subheading
            Tag="h1"
            colourScheme={SubheadingColourScheme.WHITE_TEXT_BLACK_SHADOW}
            className="mobileOnly"
            style={{ marginBottom: 0 }}
          >
            Win <strong>epic</strong> prizes
          </Subheading>

          <p className="subtitle">
            Pick numbers. Match to win.<br />
            Choose your prize.
          </p>

          <RoundedButton onClick={ctaHandler}>
            Enter now
          </RoundedButton>

          <a className="trustpilotLogo" href="https://uk.trustpilot.com/review/daymade.co.uk" target="_blank" rel="noreferrer">
            <img
              src={`${ASSET_PREFIX}/images/landing/omaze-variant/trustpilot-white.svg`}
              alt="Trustpilot logo"
              width="100%"
            />
          </a>
        </div>

        <div className="desktopOnly flexCentered">
          <Subheading
            Tag="h1"
            colourScheme={SubheadingColourScheme.WHITE_TEXT_BLACK_SHADOW}
            className="desktopOnly"
          >
            Win <strong>epic</strong> prizes
          </Subheading>

          <p className="subtitle">
            Pick numbers. Match to win.<br />
            Choose your prize.
          </p>

          <RoundedButton onClick={ctaHandler}>
            Enter now
          </RoundedButton>

          <a className="trustpilotLogo" href="https://uk.trustpilot.com/review/daymade.co.uk" target="_blank" rel="noreferrer">
            <img
              src={`${ASSET_PREFIX}/images/landing/omaze-variant/trustpilot-white.svg`}
              alt="Trustpilot logo"
              width="100%"
            />
          </a>
        </div>

        <style jsx>
          {`
            .heroContent {
              width: 100%;
              padding: 50% 5% 10%;
            }
            
            .mobileOnly {
              display: flex;
              width: 100%;
            }
            
            .desktopOnly {
              display: none;
              width: 100%;
            }
            
            .om.heroContent p.subtitle {
              color: white;
              font-weight: 800;
              text-align: center;
              font-size: 1.6em;
              line-height: 1.25em;
              margin-top: 0.4em;
            }
            
            :global(.om.heroContent .roundedButton) {
              width: 60%;
              max-width: 250px;
              align-self: center;
              margin-top: 0.5em;
            }
            
            .trustpilotLogo {
              width: 20%;
              margin-top: 2em;
              max-width: 90px;
            }
            
            :global(.heroContent .subheading) {
              font-size: 2.8em;
            }
            
            @media(max-width: 350px) {
              :global(.heroContent .subheading) {
                font-size: 2.5em;
              }
              
              .om.heroContent p.subtitle {
                font-size: 1.4em;
              }
            } 
            
            @media(min-width: 450px) {
              :global(.heroContent .subheading) {
                font-size: 3.5em;
              }
              
              .om.heroContent p.subtitle {
                font-size: 2em;
              }
            }
            
            @media(min-width: 700px) {
              .heroContent {
                width: 100%;
                padding-top: 20%;
                padding-bottom: 4%;
              }
            
              .mobileOnly {
                display: none;
              }
              
              .desktopOnly {
                display: flex;
              }
              
              .trustpilotLogo {
                margin-top: 3%;
              }
            }
            
            @media(min-width: 900px) {
              :global(.heroContent .subheading) {
                font-size: 4.5em;
              }
              
              .om.heroContent p.subtitle {
                font-size: 2.2em;
                margin-top: 0;
              }
              
              :global(.om.heroContent .roundedButton) {
                font-size: 1.2em;
              }
            }
            
            @media(min-width: 1500px) {
              :global(.heroContent .subheading) {
                font-size: 6.5em;
              }
              
              .om.heroContent p.subtitle {
                font-size: 3em;
              }
              
              :global(.om.heroContent .roundedButton) {
                font-size: 1.4em;
              }
            }
          `}
        </style>
      </div>
    );
  }

  return (
    <div className="heroContent fullWidth flexCentered">
      <Subheading colourScheme={SubheadingColourScheme.WHITE_TEXT_BLACK_SHADOW}>
        Win <strong>epic</strong> prizes
      </Subheading>

      <p id={s.subheading} style={{ textAlign: 'center' }}>
        Every Tuesday & Friday at 5pm
      </p>

      { withCtaAndTreeMessage && (
        <>
          { ctaText &&
          <RoundedButton id={s.heroEnterNowButton} onClick={ctaHandler}>
            {ctaText}
          </RoundedButton>
          }

          <div className={s.treesForTheFutureSection}>
            <Link href="/giving-back">
              <a className={s.treesForTheFutureContainer} style={{ color: 'white', whiteSpace: 'pre-wrap', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
                <img
                  className="treesForTheFutureLogo"
                  src={`${ASSET_PREFIX}/images/eden-logo-white.svg`}
                  alt="Eden logo"
                />
                <p className={s.treesForTheFutureDescription}>
                  Plant a tree with every purchase
                </p>
              </a>
            </Link>
          </div>
        </>
      )}

      <style jsx>
        {`
        
        .heroContent {
          padding: 50% 0 5%;
          color: white; 
          text-shadow: 1px 1px 3px #00000090;
        }
        
        .treesForTheFutureLogo {
          height: 35px;
          margin: 0 0.5em 1em;
        }
        
        :global(.heroContent .subheading) {
          font-size: 2.6em;
          margin-bottom: 0.1em;
        }
        
        @media(min-width: 330px) {
          .heroContent {
            padding: 55% 0 5%;
          }
          
          :global(.heroContent .subheading) {
            font-size: 2.7em;
          }
        }
  
        @media(min-width: 350px) {
          :global(.heroContent .subheading) {
            font-size: 2.9em;
          }
        }
        
        @media(min-width: 415px) {
          :global(.heroContent .subheading) {
            font-size: 3.3em;
          }
        }
        
        @media(min-width: 500px) {
          :global(.heroContent .subheading) {
            font-size: 4.3em;
          }
        }
        
        @media(min-width: 600px) {
          :global(.heroContent .subheading) {
            font-size: 5em;
          }
        }
  
        @media(min-width: 750px) {
          :global(.heroContent .subheading) {
            font-size: 6em;
          }
        }
        
        @media(min-width: 1000px) {
          :global(.heroContent .subheading) {
            font-size: 7em;
          }
        }
        
        @media(min-width: 1100px) {
          :global(.heroContent .subheading) {
            font-size: 8em;
          }
        }
        
        @media(min-width: 1300px) {
          :global(.heroContent .subheading) {
            font-size: 9em;
          }
        }
  
        @media(min-width: 1600px) {
          :global(.heroContent .subheading) {
            font-size: 10em;
          }
        }
              
        @media(min-width: 610px) {
          .treesForTheFutureLogo {
            height: 40px;
            margin-top: 1em;
          }
        }
        
        @media(min-width: 900px) {
          .treesForTheFutureLogo {
            height: 50px;
          }
        }
  
        @media(min-width: 1080px) {
          .heroContent {
            padding: 16% 0 1%;
          }
          
          .treesForTheFutureLogo {
            margin-bottom: 1.5em;
          }
        }
        
        @media(min-width: 1300px) {
          .heroContent {
            padding: 18% 0 2%;
          }
        }
        `}
      </style>
    </div>
  );
};

const MobileHero = {
  mp4Src: `${ASSET_PREFIX}/videos/22-09-08-hero-mobile-gmail-medium.mp4`,
  jpegPosterSrc: `${ASSET_PREFIX}/images/landing/stronger-variant/hero-poster-portrait-2022-09-08.jpg`,
};

const DesktopHero = {
  mp4Src: `${ASSET_PREFIX}/videos/22-09-08-hero-desktop-gmail-large.mp4`,
  jpegPosterSrc: `${ASSET_PREFIX}/images/landing/stronger-variant/hero-poster-landscape-2022-09-08.jpg`,
};

const HomePageHeroSection = ({
  withCtaAndTreeMessage,
  ctaText,
  ctaHandler,
  omazeVariant,
}) => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleResize() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const heroSource = width >= 1080 ? DesktopHero : MobileHero;

  return (
    <div className="homePageHeroSection fullWidth flexCentered">
      <div className="heroVideo fullWidth flexCentered">
        <VideoHero
          id={heroSource.mp4Src}
          mp4Src={heroSource.mp4Src}
          jpegPosterSrc={heroSource.jpegPosterSrc}
          opacity={0.8}
        >
          <HeroContent
            withCtaAndTreeMessage={withCtaAndTreeMessage}
            ctaText={ctaText}
            ctaHandler={ctaHandler}
            omazeVariant={omazeVariant}
          />
        </VideoHero>
      </div>

      <style jsx>
        {`
          .homePageHeroSection {
            margin-top: -80px;
          }

          .heroVideo {
            display: inline-block;
          }
        `}
      </style>
    </div>
  );
};

export default HomePageHeroSection;
