import { useState, useEffect } from 'react';
import { User } from 'types/User';

export const useSubscriberStatus = (currentUser: User | null) => {
  const [subscriberStatus, setSubscriberStatus] = useState<
    'PREMIUM' | 'LITE' | 'FREEMIUM' | null
  >(null);
  const [isFreemiumSubscriber, setIsFreemiumSubscriber] = useState(false);
  const [isPremiumSubscriber, setIsPremiumSubscriber] = useState(false);
  const [isPayingSubscriber, setIsPayingSubscriber] = useState(false);

  useEffect(() => {
    if (currentUser) {
      const status = currentUser.subscriberStatus ?? null;
      setSubscriberStatus(status);
      setIsFreemiumSubscriber(status === 'FREEMIUM');
      setIsPremiumSubscriber(status === 'PREMIUM');
      setIsPayingSubscriber(status === 'PREMIUM' || status === 'LITE');
    }
  }, [currentUser]);

  return {
    subscriberStatus,
    isFreemiumSubscriber,
    isPremiumSubscriber,
    isPayingSubscriber,
  };
};
