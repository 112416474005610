import React from 'react';
import strongerVariantStyles from '../StrongerVariant.module.css';
import s from './OurImpactSection.module.css';
import { ASSET_PREFIX } from 'utils/constants';
import Link from 'next/link';
import Chevron from 'components/Chevron';
import FittedText from 'components/FittedText';
import Subheading, { SubheadingColourScheme } from 'components/Subheading';

const OurImpactSection = ({ treesPlantedCount }) => {
  return (
    <div className={`sectionContainer ${strongerVariantStyles.sectionContainer}`} id={s.ourImpactSectionContainer} style={{ fontSize: '1.1em' }}>

      <div className="paddedMaxWidthContainer" id={s.paddedMaxWidthContainer}>
        <Subheading underlineColor='#D2F1AF'>
          Win Prizes & Support Amazing <strong>Causes</strong>
        </Subheading>

        <h3 style={{ textTransform: 'none', letterSpacing: 'normal', fontWeight: 800, fontSize: '1.3em', marginTop: '0.3em' }}>
          Our Impact
        </h3>

        <p style={{ maxWidth: 800 }}>
          Because of our players, we’re able to fund amazing projects like these:
        </p>
      </div>

      <div className={s.ourImpactFlex}>
        <div className={s.ourImpactFlexItem}>
          <div className={s.imageContainer} style={{ backgroundImage: `url(${ASSET_PREFIX}/images/landing/stronger-variant/our-impact/plant-a-tree.jpg)`, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontSize: '1.8em' }}>
            <Subheading colourScheme={SubheadingColourScheme.WHITE_TEXT_BLACK_SHADOW} style={{ margin: 0, fontSize: '1.8em' }}>
              <strong>{parseInt(treesPlantedCount, 10).toLocaleString()}</strong>
            </Subheading>
            <FittedText
              text="trees planted to date"
              imageUrl="/landing/omaze-variant/trees-planted-to-date.svg"
              style={{ width: '200px' }}
            />
          </div>
          <h4 className={s.impactHeading} style={{ letterSpacing: 'normal', textTransform: 'none' }}>
            Plant a tree with every purchase
          </h4>
          <p>
            For every DAYMADE purchase, we plant a tree with {''}
            <Link href="https://edenprojects.org">
              <a style={{ margin: 0, textDecoration: 'underline', wordBreak: 'break-word', whiteSpace: 'normal' }} target="_blank">
                Eden Reforestation Projects
              </a>
            </Link>.
          </p>
          <Link href="/giving-back">
            <a className="seeMoreButton">
              Read more
              <Chevron
                direction="right"
                style={{
                  fontSize: '1em',
                  marginLeft: '0.7em',
                  height: 'auto',
                  display: 'inline-block',
                }}
              />
            </a>
          </Link>
        </div>

        <div className={s.ourImpactFlexItem}>
          <div className={s.imageContainer} style={{ backgroundImage: `url(${ASSET_PREFIX}/images/landing/stronger-variant/our-impact/support-local-businesses.jpg)` }} />
          <h4 className={s.impactHeading} style={{ letterSpacing: 'normal', textTransform: 'none' }}>
            Support local businesses
          </h4>
          <p>
            Along with several prize options from smaller, local businesses, we offer a {''}
            <Link href="https://townandcitygiftcards.com">
              <a style={{ margin: 0, textDecoration: 'underline', whiteSpace: 'break-spaces' }} target="_blank">
                Town & City gift card
              </a>
            </Link>
            {''} which can be used in local venues.
          </p>
          <Link href="/prizes">
            <a className="seeMoreButton">
              View prizes
              <Chevron
                direction="right"
                style={ {
                  fontSize: '1em',
                  marginLeft: '0.7em',
                  height: 'auto',
                  display: 'inline-block',
                } }
              />
            </a>
          </Link>
        </div>

        <div className={s.ourImpactFlexItem}>
          <div className={s.imageContainer} style={{ backgroundImage: `url(${ASSET_PREFIX}/images/landing/stronger-variant/our-impact/carbon-neutral.jpg)` }} />
          <h4 className={s.impactHeading} style={{ letterSpacing: 'normal', textTransform: 'none' }}>
            Carbon neutral workforce
          </h4>
          <p>
            We work with Offset Earth - a charity that works to remove more greenhouse gases than our team's carbon footprint puts in.
          </p>
          <a className='seeMoreButton' href="https://offset.earth/thtravelltd" target="_blank" rel="noopener noreferrer"
             title="View our Offset Earth profile">
            Read more
            <Chevron
              direction="right"
              style={ {
                fontSize: '1em',
                marginLeft: '0.7em',
                height: 'auto',
                display: 'inline-block',
              } }
            />
          </a>
        </div>
      </div>

      <style jsx>
        {`
          .seeMoreButton {
            font-style: italic;
          }
        `}
      </style>
    </div>
  );
};

export default OurImpactSection;
