import TrustpilotRatingWidget from 'components/TrustpilotRatingWidget';
import EntryMethodSelectionSection from 'components/landing/EntryMethodSelectionSection';
import InfoGrid from 'components/landing/OmazeVariant/InfoGrid';
import KeyPointsBar from 'components/landing/OmazeVariant/KeyPointsBar';
import JoinTheFunSection from 'components/landing/StrongerVariant/JoinTheFunSection/JoinTheFunSection';
import OurImpactSection from 'components/landing/StrongerVariant/OurImpactSection/OurImpactSection';
import WelcomeBackSection from 'components/landing/StrongerVariant/PrizesSection/WelcomeBackSection';
import SignUpAndSaveSection from 'components/landing/StrongerVariant/SignUpAndSaveSection/SignUpAndSaveSection';
import TrendingThisMonthSection from 'components/landing/StrongerVariant/TrendingThisMonthSection/TrendingThisMonthSection';
import WhyEnterSection from 'components/landing/StrongerVariant/WhyEnterSection/WhyEnterSection';
import WinnersSection from 'components/landing/StrongerVariant/WinnersSection/WinnersSection';
import WinnersAndTreesSection from 'components/landing/WinnersAndTreesSection';
import WinnerContentSection from 'components/landing/OmazeVariant/WinnerContentSection';
import { useUserStore } from 'hooks/stores/useUserStore';
import { ctaHandler } from 'utils/cta/ctaHandler';
import { getCtaText } from 'utils/cta/getCtaText';
import HowItWorksScrollingGreenPageSection from './HowItWorksScrollingGreenPageSection';
import { IHome } from 'types/ui/Home';
import { useSubscriberStatus } from 'hooks/useSubscriberStatus';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';

export const MainContent = observer(({ prizesWonCount, treesPlantedCount }: IHome) => {
  const { currentUser } = useUserStore();
  const { subscriberStatus, isPremiumSubscriber } =
    useSubscriberStatus(currentUser);

  return (
    <>
      {!currentUser ? (
        <div className="omVariant fullWidth flexCentered">
          <KeyPointsBar />

          <InfoGrid treesPlantedCount={treesPlantedCount} />

          <HowItWorksScrollingGreenPageSection />

          <TrendingThisMonthSection
            ctaText={getCtaText(subscriberStatus)}
            ctaHandler={ctaHandler(subscriberStatus)}
            omazeVariant
          />

          <WinnerContentSection
            onCtaClick={ctaHandler(subscriberStatus)}
          />

          <EntryMethodSelectionSection />
        </div>
      ) : (
        <>
          {!subscriberStatus /* existing subscribers should not see this section */ ? (
            <EntryMethodSelectionSection withTrustpilotWidget />
          ) : (
            <>
              <HowItWorksScrollingGreenPageSection />

              <TrustpilotRatingWidget
                widgetId="trustpilot-rating-widget-home-page" /* needed because some pages show more than one trustpilot logo */
                style={{ margin: '3em 0 0' }}
              />
            </>
          )}

          <WelcomeBackSection
            id="welcomeBackSection" // Used to scroll to when they log in
            subscriberStatus={subscriberStatus}
            firstName={currentUser?.first_name}
          />

          <TrendingThisMonthSection
            ctaText={getCtaText(subscriberStatus)}
            ctaHandler={ctaHandler(subscriberStatus)}
          />

          <WinnersAndTreesSection
            treeCount={treesPlantedCount}
          />

          <WinnersSection
            ctaText={getCtaText(subscriberStatus)}
            ctaHandler={ctaHandler(subscriberStatus)}
            withCta
          />

          {currentUser !== null && <SignUpAndSaveSection sectionType={1} />}

          {currentUser === null && (
            <WhyEnterSection
              pullDownAboveSection={false}
              ctaText={getCtaText(subscriberStatus)}
              ctaHandler={ctaHandler(subscriberStatus)}
              premiumView={isPremiumSubscriber}
            />
          )}

          <OurImpactSection treesPlantedCount={treesPlantedCount} />

          <JoinTheFunSection
            ctaText={getCtaText(subscriberStatus)}
            ctaHandler={ctaHandler(subscriberStatus)}
            premiumView={isPremiumSubscriber}
          />
        </>
      )}
    </>
  );
});
