import RoundedButton from 'components/RoundedButton';
import svStyles from 'components/landing/StrongerVariant/StrongerVariant.module.css';
import GreenPageSection from 'components/GreenPageSection';
import Subheading from 'components/Subheading';

const JoinTheFunSection = ({ ctaText, ctaHandler, premiumView }) => (
  <GreenPageSection additionalClassName="joinTheFunSection fullWidth flexCentered">
    { !premiumView && (
      <p className={svStyles.whiteShadow} style={{ margin: 0 }}>
        <i>Ready to</i>
      </p>
    )}

    { !premiumView && (
      <Subheading style={{ marginTop: '0.2em' }}>
        join the <strong>fun?</strong>
      </Subheading>
    )}

    { premiumView && (
      <Subheading>
        Good<strong>luck!</strong>
      </Subheading>
    )}

    <p style={{ fontWeight: 700 }}>
      Winning EPIC prizes has never been easier!
    </p>

    {ctaText && (
      <RoundedButton onClick={ctaHandler} style={{ marginTop: '1em', minWidth: '300px', textShadow: 'none' }}>
        {ctaText}
      </RoundedButton>
    )}

    <style jsx>
      {`
        :global(.joinTheFunSection .subheading) {
          font-size: 3.5em;
        }
      `}
    </style>
  </GreenPageSection>
);

export default JoinTheFunSection;
