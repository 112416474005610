import { colors } from 'utils/constants';

export const Tab = ({ tab, titleComponent, selected, onSelect, width }) => (
  <div className={`tab${selected ? ' active' : ''}`} onClick={() => onSelect(tab)}>
    <p style={{ fontWeight: '700' }}>
      {titleComponent}
    </p>

    <style jsx>{`
      .tab {
        background: white;
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 1em 1em 1em 1em;
        width: ${width};
        cursor: pointer;
        transition: 0.2s;
        justify-content: center;
        border-bottom: 5px solid white;
        font-family: 'Montserrat', sans-serif !important;
        text-align: center;
        font-size: 1.1em;
        color: ${colors.primaryBrandGray};
        text-transform: capitalize;
        border-bottom: 5px solid #e1e1e1;
      }
      
      .tab:hover:not(.active) {
        border-bottom: 5px solid #A8A8A880;
        transition: 0.2s;
      }
      
      .tab.active {
        cursor: default;
        border-bottom: 5px solid #4A4A4A;
      }
    `}
    </style>
  </div>
);

const TabBar = ({ tabLabels, selectedTab, onSelect, style}) => {
  const tabWidth = `${100 / Object.keys(tabLabels).length}%`;

  return (
    <div className="container" style={{ width: '100%', position: 'relative' }}>
      <div className="bar" id="tabBar" style={style}>
        { tabLabels.map((tabLabel, idx) => (
          <Tab
            key={tabLabel}
            tab={idx}
            titleComponent={<>{tabLabel.toLowerCase()}</>}
            onSelect={onSelect}
            selected={selectedTab === idx}
            width={tabWidth}
          />
        ))}

        <style jsx>
          {`
            .bar {
              display: flex;
              width: 100%;
              background-color: #B9B9B9;
              overflow-x: auto;
              overflow-y: hidden;
            }
            
            .bar::-webkit-scrollbar {
              display: none;
            }
          `}
        </style>
      </div>
    </div>
  );
};

export default TabBar;
