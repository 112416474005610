import WeeklyDrawPrizeTable from 'components/WeeklyDrawPrizeTable';
import ToggleButton from 'components/ToggleButton';
import { useState } from 'react';
import TabBar from 'components/TabBar';

const WeeklyDrawPrizeTableAllPlans = () => {
  const [tabIdx, setTabIdx] = useState(0);
  const tabLabels = ['PREMIUM', 'LITE', 'FREE'];
  const [prizeTableExpanded, setPrizeTableExpanded] = useState(true);


  return (
    <div className="weeklyDrawPrizeTableAllPlans flexCentered fullWidth">
      <TabBar
        tabLabels={tabLabels}
        selectedTab={tabIdx}
        onSelect={async (newIdx) => setTabIdx(newIdx)}
        style={{
          margin: '0 auto 1.5em',
          maxWidth: 700,
        }}
      />

      <WeeklyDrawPrizeTable
        expanded={prizeTableExpanded}
        type={tabLabels[tabIdx]}
        withPrizesPageLinks={true}
      />

      { !prizeTableExpanded && tabLabels[tabIdx] === 'PREMIUM' &&
        <ToggleButton style={{ margin: '1em auto', flexDirection: 'column-reverse' }} toggleHandler={() => setPrizeTableExpanded(true)}>
          See more
        </ToggleButton>
      }
    </div>
  );
}

export default WeeklyDrawPrizeTableAllPlans;
