import { useEffect, useState } from 'react';
import { userIsEligibleForFirstEntryFreeDeal } from 'utils/api/subscriptions';
import { getCookie } from 'utils/cookie';

export const useEligibilityCheck = () => {
  const [eligibleForIntroductoryOffer, setIsEligibleForIntroOffer] = useState(true);

  useEffect(() => {
    const checkEligibility = async () => {
      const jwt = getCookie('jwt', null);

      if (jwt) {
        const introOfferResult = await userIsEligibleForFirstEntryFreeDeal(jwt);
        if (introOfferResult?.eligible === false) {
          setIsEligibleForIntroOffer(false);
        }
      }
    };

    checkEligibility();
  }, []);

  return { eligibleForIntroductoryOffer };
};