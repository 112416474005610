import Subheading from 'components/Subheading';
import Link from 'next/link';
import CardList from 'components/CardList';
import BlogArticleCard from 'components/blog/BlogArticleCard';
import Chevron from 'components/Chevron';
import Separator from 'components/Separator';
import { BLOG_ARTICLES } from '../../constants/Blogs';

const NUMBER_OF_FEATURED_BLOG_POSTS = 3;

const CheckOutBlogSection = () => (
  <div className='flexCentered' style={{ padding: 0, width: '100%', maxWidth: 1800, marginTop: '3em' }}>
    <div id="checkOutBlogContentGrid" style={{ padding: 0 }}>
      <Subheading id="blogHeading" underlineColor='transparent' style={{ borderLeft: '8px solid #B9F5E6', textAlign: 'left', paddingLeft: '0.4em' }}>
        Check Out Our <strong>Blog</strong>
      </Subheading>
      <p className='blogDescriptionParagraph'>
        Discover more DAYMADE with {''}
        <Link href="/blog">
          <a style={{ margin: 0, textDecoration: 'underline' }}>
            our blog
          </a>
        </Link>, featuring our top prize picks, travel guides, and more.
      </p>
    </div>

    <CardList id="recentBlogPostsList" className="blogPosts" style={{ maxWidth: 960 }}>
      { BLOG_ARTICLES.filter(
        (article) => article.href !== '/blog/Top-8-Christmas-Stocking-Fillers-2022' /* Hiding this because apparently it's weird to promote a Christmas blog post in March 🥲 This filter can be removed after we've added a couple more blog articles */
      ).slice(0, NUMBER_OF_FEATURED_BLOG_POSTS).map((article) => (
        <BlogArticleCard
          key={article.href}
          title={article.title}
          subheading={article.subheading}
          datePostedString={article.datePostedString}
          imageDirectory={article.imageDirectory}
          href={article.href}
          backgroundPosition={article.backgroundPosition}
          style={{
            minWidth: 285,
            height: 230,
            fontSize: '1em',
          }}
        />
      ))}
    </CardList>

    <Link href='/blog'>
      <a className='viewMoreLink'>
        View more <Chevron direction='right' style={{ display: 'inline', verticalAlign: 'middle', marginLeft: '0.5em', fontSize: '0.9em' }} />
      </a>
    </Link>

    <Separator
      style={{
        width: '97%',
        margin: '0 auto',
        maxWidth: 928,
      }}
    />

    <style jsx>
      {`
        :global(#checkOutBlogContentGrid) {
          margin-top: 2.5em;
          display: grid;
          grid-template-columns: 50% 50%;
          grid-gap: 1em;
          max-width: 1000px;
          margin: 0 3em;
          width: auto;
        }
        
        :global(#blogHeading) {
          grid-column: 1 / 3;
          grid-row: 1;
        }
        
        .blogDescriptionParagraph {
          grid-column: 1 / 3;
          grid-row: 2;
          align-self: flex-start;
          line-height: 1.8;
          font-size: 1.2em;
        }
        
        .viewMoreLink {
          font-style: italic;
          align-self: center;
          margin: 2em;
          font-size: 1.2em;
        }
        
        :global(.cardList a.blogArticleCard) {
          margin: 0.5em 1em;
        }
        
        :global(.cardList a.blogArticleCard:first-child) {
          margin: 0.5em 1em 0 0;
        }
        
        :global(.cardList a.blogArticleCard:nth-child(3)) {
          margin: 0.5em 0 0 1em;
        }
        
        @media(min-width: 600px) {
          :global(#checkOutBlogContentGrid) {
            margin-top: 2em;
            max-width: 930px;
          }
        
          :global(#blogHeading) {
            grid-column: 1 / 2;
            grid-row: 1;
          }
        
          .blogDescriptionParagraph {
            grid-column: 2 / 3;
            grid-row: 1;
          }
          
          :global(.cardList a.blogArticleCard),
          :global(.cardList a.blogArticleCard:first-child),
          :global(.cardList a.blogArticleCard:nth-child(3)) {
            margin-top: 2em;
          }
        }
      `}
    </style>
  </div>
);

export default CheckOutBlogSection;
