import Head from 'next/head';
import React from 'react';
import MainLayout from 'components/layout/MainLayout';
import { colors } from 'utils/constants';
import { getHomePageStats } from 'api/analytics';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import HomePageHeroSection from 'components/landing/StrongerVariant/HomePageHeroSection/HomePageHeroSection';
import SocialMediaSection from 'components/landing/SocialMediaSection';
import CheckOutBlogSection from 'components/landing/CheckOutBlogSection';
import FaqSection from 'components/landing/FaqSection';
import { HomePageCindyVariantFAQs } from 'constants/FAQs';
import { useUserStore } from 'hooks/stores/useUserStore';
import { observer } from 'mobx-react-lite';
import { getCtaText } from 'utils/cta/getCtaText';
import { ctaHandler } from 'utils/cta/ctaHandler';
import { MainContent } from 'components/home-page/MainContent';

import { useSubscriberStatus } from 'hooks/useSubscriberStatus';
import { useDrawStore } from 'hooks/stores/useDrawStore';

export const OriginalHome = observer(() => {
  const { treesPlantedCount, prizesWonCount } = useDrawStore();

  const { currentUser } = useUserStore();
  const { subscriberStatus } = useSubscriberStatus(currentUser);

  return (
    <React.Fragment>
      <HomePageHeroSection
        withCtaAndTreeMessage
        ctaText={getCtaText(subscriberStatus)}
        ctaHandler={ctaHandler(subscriberStatus)}
        omazeVariant={!currentUser}
      />

      <MainContent
        prizesWonCount={prizesWonCount!}
        treesPlantedCount={treesPlantedCount!}
      />
      <div
        className="flexCentered"
        style={{ width: '100%', background: colors.footerBackground }}
      >
        <FaqSection faqs={HomePageCindyVariantFAQs} />

        <CheckOutBlogSection />

        <SocialMediaSection />
      </div>
      <style jsx>
        {`
          :global(
              .homePage
                p:not(.ignoreGlobalStyles):not(.popup p):not(.headerContainer p)
            ) {
            font-size: 1.1em;
          }

          :global(.omVariant a:not(.no-underline)) {
            text-decoration: underline;
          }

          :global(.omVariant .prizeSubheading) {
            font-size: 1em;
          }

          @media (min-width: 700px) {
            :global(.omVariant .subheading) {
              font-size: 2.5em;
            }

            :global(
                .homePage
                  p:not(.ignoreGlobalStyles):not(.popup p):not(
                    .headerContainer p
                  )
              ) {
              font-size: 1.25em;
            }

            :global(.omVariant .prizeSubheading) {
              font-size: 1.1em;
            }
          }
        `}
      </style>
    </React.Fragment>
  );
});

export default OriginalHome;
