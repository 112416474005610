import Subheading from 'components/Subheading';

const SocialMediaSection = () => (
  <div id='socialMediaContentGrid' style={{ padding: '0.5em 0 3em' }}>
    <Subheading id="socialHeading" underlineColor='transparent' style={{ borderLeft: '8px solid #B9F5E6', textAlign: 'left', paddingLeft: '0.4em' }}>
      We'd Love To Get To Know <strong>You!</strong>
    </Subheading>
    <p className='socialDescriptionParagraph'>
      Don't forget to tag us in your winning story or prize win to be featured on the winners wall! Follow our socials using the links below.
    </p>

    <style jsx>
      {`
        :global(#socialMediaContentGrid) {
          display: grid;
          grid-template-columns: 50% 50%;
          grid-gap: 1em;
          max-width: 928px;
          margin: 2em 3em 0;
          width: auto;
        }
        
        :global(#socialHeading) {
          grid-column: 1 / 3;
          grid-row: 1;
        }
        
        .socialDescriptionParagraph {
          font-size: 1.2em;
          grid-column: 1 / 3;
          grid-row: 2;
          align-self: flex-start;
          line-height: 1.8;
        }
          
        @media(min-width: 600px) {
          :global(#socialHeading) {
            grid-column: 1 / 2;
            grid-row: 1;
          }
        
          .socialDescriptionParagraph {
            grid-column: 2 / 3;
            grid-row: 1;
          }
        }
      `}
    </style>
  </div>
);

export default SocialMediaSection;