import React  from 'react';
import GreenPageSection from 'components/GreenPageSection';
import Subheading from 'components/Subheading';
import { colors } from 'utils/constants';

const KeyPointsBar = () => {

  return (
    <GreenPageSection additionalClassName="keyPointsBar">
      <div className="keyPoints">
        <div>
          <Subheading className="keyPoint">
            <strong>Thousands of winners every week</strong>
          </Subheading>
        </div>

        <div className="fullWidth">
          <Subheading className="keyPoint">
            <strong>700+</strong>
          </Subheading>
          <p className="keyPointText ignoreGlobalStyles">5 star reviews</p>
        </div>

        <div className="fullWidth">
          <Subheading className="keyPoint">
            <strong>250+</strong>
          </Subheading>
          <p className="keyPointText ignoreGlobalStyles">epic prizes</p>
        </div>
      </div>

      <style jsx>
        {`
          :global(.greenPageSection.keyPointsBar) {
            padding: 0.7em 0;
            width: 95%;
          }

          .keyPoints {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            justify-items: center;
          }

          .keyPoints > div {
            width: 100%;
            padding: 0 0.2em;
          }

          .keyPoints > div:not(:last-child) {
            border-right: 2px solid ${colors.primaryBrandGray};
          }

          :global(.keyPoints .subheading) {
            font-size: 1.8em;
            margin-bottom: 0;
          }

          p.keyPointText {
            text-transform: uppercase;
            text-shadow: 0.08em 0.08em white;
            font-weight: 700;
            font-size: 0.85em;
          }

          @media (max-width: 350px) {
            p.keyPointText {
              font-size: 0.75em;
            }
          }

          @media (min-width: 400px) {
            :global(.greenPageSection.keyPointsBar) {
              padding: 0.8em 0;
              width: 90%;
            }

            :global(.keyPoints .subheading) {
              font-size: 2.3em;
            }

            p.keyPointText {
              font-size: 0.9em;
            }
          }

          @media (min-width: 700px) {
            :global(.greenPageSection.keyPointsBar) {
              padding: 2em 0;
            }

            :global(.keyPoints .subheading.keyPoint) {
              font-size: 3.4em;
            }

            p.keyPointText {
              font-size: 1.3em;
            }
          }
        `}
      </style>
    </GreenPageSection>
  );
};

export default KeyPointsBar;
